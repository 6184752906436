<template>
  <Loader v-if="isLoading" />
  <div class="mt-4 mb-5" v-else>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-success fw-bold fs-5">About yourself</p>
    </div>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-muted">
        Please fill all the information as in your identity documents.
      </p>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card w-50">
        <div class="card-body">
          <form @submit.prevent="submitHandler">
            <div class="mb-3">
              <label for="first-name" class="form-label">First Name</label>
              <input
                type="text"
                class="form-control custom-input-color"
                id="first-name"
                v-model="formData.first_name"
              />
            </div>
            <div class="mb-3">
              <label for="last-name" class="form-label">Last Name</label>
              <input
                type="text"
                class="form-control custom-input-color"
                id="last-name"
                v-model="formData.last_name"
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email Address</label>
              <input
                type="email"
                class="form-control custom-input-color"
                id="email"
                v-model="formData.email"
              />
              <p class="text-danger mt-1" v-if="isValidEmail">
                Invalid email address
              </p>
            </div>
            <div class="mb-3">
              <label for="date" class="form-label">Date of Birth</label>
              <VueDatePicker
                v-model="formData.dob"
                class="form-control"
                id="custom-datepicker-border"
                :enable-time-picker="false"
                :max-date="new Date()"
                month-name-format="long"
                :clearValue="true"
                @update:model-value="dateSelected"
                :format="format"
                @open="handleFocus"
                @closed="handleFocusOut"
                @cleared="clearedDob"
                calendar-cell-class-name="dp-custom-cell"
                auto-apply
              ></VueDatePicker>
            </div>
            <div class="mb-3">
              <label for="city" class="form-label">City</label>
              <input
                type="text"
                class="form-control custom-input-color"
                id="city"
                v-model="formData.city"
              />
            </div>
            <div class="mb-3">
              <label for="address" class="form-label">Address</label>
              <input
                type="text"
                class="form-control custom-input-color"
                id="address"
                v-model="formData.address"
              />
            </div>
            <div class="mb-3">
              <label for="inputState" class="form-label">Country</label>
              <span class="input-group-text ps-0" id="custom-input-group">
                <multiselect
                  v-model="selectedCountry"
                  :options="countryList"
                  @select="selectCountryHandler"
                  @open="handleFocusSelect"
                  @close="handleFocusOutSelect"
                  @remove="removeCountry"
                  placeholder=""
                  :multiple="false"
                  track-by="name"
                  :custom-label="customCountryLabel"
                  :show-labels="false"
                  :allow-empty="false"
                  style="line-height: 2"
                >
                  <template v-slot:singleLabel="{ option }">
                    <span class="">{{ option.name }}</span>
                  </template>
                </multiselect>
              </span>
            </div>

            <div class="mb-3">
              <label for="mobile_number" class="form-label"
                >Mobile Number</label
              >
              <div class="input-group">
                <span
                  class="input-group-text"
                  :class="{ 'd-none': !this.formData.country }"
                  >{{ selectedCountryMobileCode }}</span
                >
                <input
                  type="text"
                  class="form-control"
                  id="mobile_number"
                  v-model="formData.mobile_number"
                />
              </div>
              <span class="text-danger mt-1" v-if="isValidMobile"
                >Invalid mobile number</span
              >
            </div>

            <div
              v-for="(field, index) in formFields"
              :key="index"
              class="form-group mb-3"
            >
              <label :for="field.field">{{ field.field }}</label>
              <template v-if="field.field === 'occupation'">
                <select
                  :id="field.field"
                  v-model="formData[field.field]"
                  class="form-control"
                >
                  <option
                    v-for="option in occupationOptions"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </template>
              <template v-else>
                <input
                  :type="getFieldType(field.field)"
                  :id="field.field"
                  v-model="formData[field.field]"
                  class="form-control"
                />
              </template>
            </div>

            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-success w-100 mb-2"
                :class="{ disabled: !formDataValidation }"
                :disabled="!formDataValidation"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import multiselect from 'vue-multiselect';
import countryCodesList from 'country-codes-list';
// import { phone } from 'phone';
import { isValidNumber } from 'libphonenumber-js';

// import { countries } from '../data/countries.js';
// import { allCountries } from '../data/allcountries.js';
import { validEmail } from '../helpers/validateEmailAddress.js';

import { validate } from '../helpers/validateInputData.js';
import Loader from '../components/Loader.vue';

import { requiredFieldSender } from '../data/requiredFieldSender.js';

export default {
  name: 'KycSection',
  data() {
    return {
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        country: '',
        address: '',
        mobile_number: '',
        city: ''
      },
      selectedCountryMobileCode: '',
      isLoading: false,
      isValidEmail: false,
      isValidMobile: false,
      countryList: [],
      selectedCountry: null,
      countryWithCodes: [],
      occupationOptions: [
        'FREELANCER',
        'NURSE',
        'OTHER',
        'LAWYER',
        'DOCTOR',
        'PUBLIC_EMPLOYEE',
        'OFFICE_WORKER',
        'UNEMPLOYED',
        'GOVERNMENT_OFFICER',
        'RETIREMENT',
        'TEACHER',
        'STUDENT',
        'HOUSEWIFE',
        'OFFICER',
        'AGRICULTURE',
        'SELF_EMPLOYED'
      ],
      formFields: []
    };
  },
  components: {
    Loader,
    multiselect
  },
  methods: {
    ...mapActions([
      'kycAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'userDetailsAc',
      'createTxAc',
      'userCountries'
    ]),

    getFieldType(fieldName) {
      if (fieldName === 'zip_code') return 'tel';
      return 'text';
    },

    customCountryLabel(option) {
      return `${option.iso3} | ${option.name}`;
    },

    selectCountryHandler(selectedOption) {
      const { iso2, iso3 } = selectedOption;
      this.formData.country = iso2;

      this.formData.mobile_number = '';

      this.isValidMobile = false;

      this.selectedCountryMobileCode = (
        this.countryWithCodes[iso2] || ''
      ).trim();

      const fields = requiredFieldSender[iso3] || [];
      this.formFields = fields;

      this.fields?.forEach((item) => {
        this.formData[item.field] = '';
      });

      this.$forceUpdate();
    },

    removeCountry() {
      this.formData.country = '';
      this.selectedCurrency = null;
    },

    format(date) {
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      // Array of month names
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];

      const monthName = monthNames[monthIndex];

      return `${day} ${monthName} ${year}`;
    },

    clearedDob() {
      this.formData.dob = '';
    },

    handleFocus() {
      const customDpWrapper = document.getElementById(
        'custom-datepicker-border'
      );
      customDpWrapper.classList.add('custom-dp-wrapper-border');
    },

    handleFocusOut() {
      const customDpWrapper = document.getElementById(
        'custom-datepicker-border'
      );
      customDpWrapper.classList.remove('custom-dp-wrapper-border');
    },

    handleFocusSelect() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleFocusOutSelect() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.remove('custom-input-group');
    },

    dateSelected(date) {
      this.formData.dob = new Date(date).toISOString().substring(0, 10);
    },

    async submitHandler() {
      try {
        this.isLoading = true;

        if (
          this.formData.mobile_number.includes(this.selectedCountryMobileCode)
        ) {
          this.formData.mobile_number = this.formData.mobile_number.slice(
            this.selectedCountryMobileCode.length
          );
        }

        this.formData.mobile_number =
          this.selectedCountryMobileCode + this.formData.mobile_number;

        const response = await this.kycAc(this.formData);

        if (response.statusCode === 200) {
          await this.userDetailsAc();

          const sToken = response.data.sessionToken;

          if (this.getRecipient.length) {
            this.$router.push({
              path: '/recipient/list',
              query: { session_token: sToken }
            });
          } else {
            this.$router.push({
              path: '/recipient/add',
              query: { session_token: sToken }
            });
          }
        }
      } catch (err) {
        this.isLoading = false;

        console.error(err);
        const statusCode = (err.response && err.response.status) || 500;
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message;
        await this.errorAc({ statusCode, message });
        this.$router.push({ path: '/error', query: { statusCode } });
      }
    },
    async extractToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const token = queryParams.get('session_token');
        await this.tokenAc('');
        await this.sessionTokenAc(token);
      }
    }
  },
  computed: {
    ...mapGetters(['getUserDetails', 'getRecipient']),
    formDataValidation() {
      if (!validate(this.formData)) {
        return false && this.isValidEmail;
      }
      return true && !this.isValidEmail;
    }
  },

  watch: {
    'formData.email': function (value) {
      if (!validEmail(value)) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },
    'formData.mobile_number': function (value) {
      if (!value) {
        this.isValidMobile = false;
        return;
      }
      const ctryCode = this.selectedCountry.iso2;
      const isValid = isValidNumber(value, ctryCode);

      if (!isValid) {
        this.isValidMobile = true;
      } else {
        this.isValidMobile = false;
      }
    }
  },

  async mounted() {
    try {
      this.isLoading = true;

      const url = window.location;
      await this.extractToken(url);

      await this.userDetailsAc();

      const countriesList = await this.userCountries();

      this.countryWithCodes = countryCodesList.customList(
        'countryCode',
        ' +{countryCallingCode}'
      );

      // pre fill the user form base user details
      this.formData.dob = this.getUserDetails.dob;
      this.formData.email = this.getUserDetails.email;
      this.formData.address = this.getUserDetails.address;
      this.formData.country = this.getUserDetails.country;

      this.formData.first_name = this.getUserDetails.first_name;
      this.formData.last_name = this.getUserDetails.last_name;
      // const codeLength = this.countryWithCodes[this.formData.country];
      this.formData.mobile_number = this.getUserDetails.mobile_number; //.slice(
      //codeLength || 0
      //);

      // this.countryList = countries;
      this.countryList = countriesList.data;

      this.selectedCountry = this.countryList.find(
        (ctry) => ctry.iso2 === this.formData.country
      );

      const ctryCode = this.selectedCountry?.iso2 || '';

      this.selectedCountryMobileCode = (
        this.countryWithCodes[ctryCode] || ''
      ).trim();

      this.formData.mobile_number = this.formData.mobile_number.slice(
        this.selectedCountryMobileCode.length
      );

      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;

      console.error(err);
      const statusCode = (err.response && err.response.status) || 500;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message;
      await this.errorAc({ statusCode, message });
      this.$router.push({ path: '/error', query: { statusCode } });
    }
  }
};
</script>

<style scoped>
.form-control,
select,
.custom-input-datepicker {
  padding: 0.75rem 0.75rem;
  background-color: #f3f3f3 !important;
  color: #2e693d !important;
  outline: none;
}

input:focus,
select:focus {
  box-shadow: none !important;
  border-color: #359f4e;
}

.custom-input-color {
  color: #2e693d !important;
  font-weight: normal;
}

.custom-input-group {
  border: 1px solid #359f4e !important;
  border-radius: 6px;
  box-shadow: none !important;
}
</style>

<style>
.dp__input {
  background: none !important;
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #2e693d;
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #2e693d;
}

.dp__today {
  color: #2e693d;
  border: 1px solid #2e693d;
  font-weight: bold;
}

.dp-custom-cell {
  border-radius: 50%;
}

.custom-dp-wrapper-border {
  border: 1px solid #2e693d !important;
}

.multiselect__tags,
.multiselect__single,
.multiselect__input {
  border: none;
  line-height: initial;
  color: #2e693d;
  background-color: transparent !important;
}
</style>
