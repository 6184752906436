import { createStore } from 'vuex';

import rootMutationsModule from './mutations.js';
import rootGettersModule from './getters.js';
import rootActionsModule from './actions.js';

const store = createStore({
  state() {
    return {
      userDetails: {},
      session: {},
      transaction: {},
      exchangeRate: {},
      assets: {},
      currency: {},
      selectedCurrency: {},
      txDetails: {
        amount: {},
        recipient: {},
        txRequest: {}
      },
      token: '',
      sessionToken: '',
      error: {
        isError: false,
        message: '',
        statusCode: null
      },
      referenceKey: '',
      transaction_id: '',
      KycData: null,
      kycUrl: '',
      callback: '',
      onChangeCallback: '',
      baseExchangeRate: {},
      bankList: []
    };
  },
  mutations: rootMutationsModule,
  actions: rootActionsModule,
  getters: rootGettersModule
});

export default store;
