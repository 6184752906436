<template>
  <Loader v-if="isLoading" />
  <div class="mt-4 mb-5" v-else>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-success fw-bold fs-5">Recipient bank account details</p>
    </div>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-muted">Please fill out all the information below.</p>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card w-50">
        <div class="card-body">
          <form @submit.prevent="recipientSubmitHandler">
            <div class="mb-3">
              <label class="form-check-label mt-1" for="recipient-type">Is this your own bank account?</label>
              <ToggleSwitchButton v-model="formData.recipient_category" label="Yes|No" />
            </div>

            <div class="mb-3">
              <label for="recipient-name" class="form-label">First name of the account holder</label>
              <input type="text" class="form-control" id="recipient-name" v-model="formData.first_name" />
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="form-label">Last name of the account holder</label>
              <input type="text" class="form-control" id="recipient-name" v-model="formData.last_name" />
            </div>

            <div class="mb-3" v-if="!formData.recipient_category">
              <label for="recipient-email" class="form-label">Recipient Email</label>
              <input type="email" class="form-control" id="recipient-email" v-model="formData.recipient_email" />
              <p class="text-danger mt-1" v-if="isValidEmail">Invalid email address</p>
            </div>
            <div class="mb-3" v-if="!formData.recipient_category">
              <label for="recipient-dob" class="form-label">Recipient DOB</label>
              <input type="date" class="form-control" id="recipient-dob" v-model="formData.dob" />
            </div>
            <div class="mb-3">
              <label for="account-number" class="form-label">{{ labelBasedOnCountry }}</label>
              <input type="text" class="form-control" id="account-number" v-model="formData.bank_account_number" />
            </div>

            <div class="mb-3">
              <label for="swift-code" class="form-label">BIC/SWIFT Code</label>
              <input type="text" class="form-control" id="swift-code" v-model="formData.account_routing_number" />
            </div>
            <div class="mb-3">
              <label for="currency" class="form-label">Currency</label>
              <span class="input-group-text ps-0" id="custom-input-group-currency">
                <multiselect
                  v-model="selectedCurrency"
                  :options="currencyList"
                  @select="selectCurrencyHandler"
                  @open="handleCurrencyFocusSelect"
                  @close="handleCurrencyFocusOutSelect"
                  @remove="removeCurrency"
                  placeholder=""
                  :multiple="false"
                  track-by="name"
                  :custom-label="customCurrencyLabel"
                  :show-labels="false"
                  :allow-empty="false"
                  :disabled="true"
                >
                  <template v-slot:singleLabel="{ option }">
                    <img
                      :src="option.icon_name && getFlag(option.icon_name.toLowerCase())"
                      width="24"
                      class="rounded-circle img-fluid me-2"
                    />
                    <span class="">{{ option.currency }}</span>
                  </template>
                  <template v-slot="{ option }">
                    <div class="option__desc">
                      <i class="bi bi-xbox option__image"></i>
                      <span class="option__small">{{ option.currency }}</span>
                      <span class="option__title">{{ option.currency_name }}</span>
                    </div>
                  </template>
                </multiselect>
              </span>
            </div>
            <div class="mb-3">
              <label for="country" class="form-label">Country</label>
              <span class="input-group-text ps-0" id="custom-input-group">
                <multiselect
                  v-model="selectedCountry"
                  :options="countryList"
                  @select="selectCountryHandler"
                  @open="handleFocusSelect"
                  @close="handleFocusOutSelect"
                  @remove="removeCountry"
                  placeholder=""
                  :multiple="false"
                  track-by="name"
                  :custom-label="customCountryLabel"
                  :show-labels="false"
                  :allow-empty="false"
                >
                  <template v-slot:singleLabel="{ option }">
                    <img
                      :src="option.iso2 && getFlag(option.iso2.toLowerCase())"
                      width="24"
                      class="rounded-circle img-fluid me-2"
                    />
                    <span class="">{{ option.name }}</span>
                  </template>
                </multiselect>
              </span>
            </div>
            <div class="mb-3" v-if="!formData.recipient_category">
              <label for="mobile_number" class="form-label">Recipient Mobile No.</label>
              <div class="input-group">
                <span class="input-group-text" :class="{ 'd-none': !this.formData.bank_country }">{{
                  selectedCountryMobileCode
                }}</span>
                <input type="text" class="form-control" id="mobile_number" v-model="formData.mobile_number" />
              </div>
              <span class="text-danger mt-1" v-if="isValidMobile">Invalid mobile number</span>
            </div>
            <div class="mb-3">
              <label for="recipient_institute" class="form-label text-capitalize">Recipient Institute</label>
              <select id="recipient_institute" class="form-select" v-model="formData.receiving_institution_id">
                <option v-for="bank in getBankList" :key="bank.id" :value="bank.id">
                  {{ bank.name }}
                </option>
              </select>
            </div>

            <div v-for="(field, index) in fields" :key="index" class="mb-3">
              <label :for="field?.field" class="form-label text-capitalize">{{ getFieldLabel(field?.field) }}</label>
              <template v-if="field?.field !== 'identification_type' && field?.field !== 'bank_account_type'">
                <input
                  :type="getFieldType(field?.field)"
                  :class="['form-control']"
                  :id="field?.field"
                  v-model="formData[field?.field]"
                />
              </template>
              <template v-else-if="field?.field === 'identification_type' || field?.field === 'bank_account_type'">
                <select :class="['form-select']" :id="field?.field" v-model="formData[field.field]">
                  <option value="" disabled>Select {{ getFieldLabel(field?.field) }}</option>
                  <option v-for="option in getFieldOptions(field?.field)" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </template>
            </div>

            <div class="mb-3" v-if="isTaxIdRequired && formData.recipient_category">
              <label for="recipient_identity_type" class="form-label text-capitalize">identification type</label>
              <select id="recipient_identity_type" class="form-select" v-model="recipient_identity_type">
                <option disabled value="">Select identification type</option>
                <option v-for="type in tax_country_identity_type" :key="type" :value="type">
                  {{ type }}
                </option>
              </select>
            </div>

            <div class="mb-3" v-if="isTaxIdRequired && formData.recipient_category">
              <label for="identity-value" class="form-label">identification number</label>
              <input type="text" class="form-control" id="identity-value" v-model="recipient_identity_value" />
            </div>

            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-success w-100"
                :class="{ disabled: !formDataValidation || isValidMobile }"
                :disabled="!formDataValidation || isValidMobile"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToggleSwitchButton from 'toggle-switch-button-vue';
import countryCodesList from 'country-codes-list';
import multiselect from 'vue-multiselect';
import { toast } from 'vue3-toastify';
import { isValidNumber } from 'libphonenumber-js';

// import { countries } from '../data/countries.js';
// import { currencies } from '../data/currencies';
import { validEmail } from '../helpers/validateEmailAddress.js';

import { validate } from '../helpers/validateInputData.js';
import Loader from '../components/Loader.vue';

import { requiredFields } from '../data/requiredFieldBanks.js';
import { routingType } from '../data/routingType.js';
import { accountType } from '../data/accountType.js';
import { identificationType } from '../data/identificationType.js';

export default {
  name: 'RecipientSection',
  data() {
    return {
      formData: {
        recipient_category: true,
        recipient_email: '',
        bank_country: '',
        account_currency: '',
        first_name: '',
        last_name: '',
        bank_account_number: '',
        account_routing_number: '',
        bank_swift_code: 'SWIFT-BIC',
        account_type: 'IBAN',
        relationship: 'SELF',
        dob: '',
        mobile_number: '',
        receiving_institution_id: ''
      },
      fields: [],
      selectedCountryMobileCode: '',
      isLoading: false,
      isValidEmail: false,
      isValidMobile: false,
      countryList: [],
      currencyList: [],
      selectedCountry: null,
      selectedCurrency: null,
      countryWithCodes: [],
      identificationTypes: ['NATIONAL_ID', 'PASSPORT', 'DRIVING_LICENSE'],
      bankAccountTypes: ['SAVINGS', 'CHECKING'],
      commonFields: ['city', 'address', 'mobile_number', 'identification_number', 'identification_type'],
      labelBasedOnCountry: 'IBAN Account Number',
      countryLabel: ['PHL', 'COL', 'EGY', 'MEX', 'USA'],
      taxRequiredCountry: ['COL', 'MEX'],
      isTaxIdRequired: false,
      tax_country_identity_type: [],
      recipient_identity_type: '',
      recipient_identity_value: ''
    };
  },
  components: {
    Loader,
    multiselect,
    ToggleSwitchButton
  },
  computed: {
    ...mapGetters(['getUserDetails', 'getCurrency', 'getBankList']),

    formDataValidation() {
      if (!validate(this.formData)) {
        return false && this.isValidEmail;
      }
      return true && !this.isValidEmail;
    }
  },
  methods: {
    ...mapActions([
      'recipientAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'recpientLinkTxAc',
      'userDetailsAc',
      'recipientCtryListAc',
      'fetchCurrencyAc',
      'fetchBankList'
    ]),
    getFieldLabel(field) {
      return field.replace(/_/g, ' ');
    },
    getFieldType(field) {
      if (field === 'zip_code' || field === 'mobile_number') {
        return 'tel';
      }
      return 'text';
    },
    isInvalidField(field) {
      return !this.formData[field];
    },
    getFieldOptions(field) {
      if (field === 'identification_type') {
        const type = identificationType[this.formData['bank_country']]?.length
          ? identificationType[this.formData['bank_country']]
          : this.identificationTypes;

        return type;
      } else if (field === 'bank_account_type') {
        return this.bankAccountTypes;
      }
      return [];
    },

    customCountryLabel(option) {
      return `${option.emoji} | ${option.iso3} | ${option.name}`;
    },

    customCurrencyLabel(option) {
      return `${option.currency} | ${option.currency_name}`;
    },

    removeCountry() {
      this.formData.bank_country = '';
      this.selectedCurrency = null;
    },

    removeCurrency() {
      this.formData.account_currency = '';
      this.selectedCountry = null;
    },
    getFlag(iso2) {
      return require(`@/assets/flags/${iso2}.svg`);
    },

    async selectCountryHandler(selectedOption) {
      const { iso3, iso2 } = selectedOption;

      this.isValidMobile = false;

      this.selectedCountryMobileCode = (this.countryWithCodes[iso2] || '').trim();

      // if (currency[0].currency !== this.formData.account_currency) {
      //   toast.error(
      //     "The selected currency and the country's currency do not match",
      //     {
      //       position: toast.POSITION.BOTTOM_CENTER,
      //       icon: false,
      //       closeButton: false,
      //       hideProgressBar: true,
      //       toastClassName: 'text-danger',
      //       bodyClassName: 'w-100',
      //       autoClose: 4500
      //     }
      //   );
      //   this.formData.bank_country = '';
      //   this.selectedCountry = null;
      //   return;
      // }
      this.formData.bank_country = iso3;

      if (this.taxRequiredCountry.includes(iso3)) {
        this.isTaxIdRequired = true;
        this.tax_country_identity_type = identificationType[iso3];
      } else {
        this.isTaxIdRequired = false;
        this.tax_country_identity_type = [];
      }

      this.fields = requiredFields[iso3] || [];

      if (this.formData.recipient_category) {
        const result = this.deleteCommonFields(this.commonFields, this.fields);
        this.fields = result;
      }

      this.fields?.forEach((item) => {
        this.formData[item.field] = '';
      });

      if (routingType[iso3]) {
        this.formData.bank_swift_code = routingType[iso3];
      } else {
        this.formData.bank_swift_code = 'SWIFT-BIC';
      }

      if (accountType[iso3]) {
        this.formData.account_type = accountType[iso3];
      } else {
        this.formData.account_type = 'IBAN';
      }

      if (this.countryLabel.includes(iso3)) {
        this.labelBasedOnCountry = 'Bank Account Number';
      } else {
        this.labelBasedOnCountry = 'IBAN Account Number';
      }

      this.$forceUpdate();
      // this.resetFormData();

      await this.fetchBankList({
        countryCode: iso3
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
    },

    deleteCommonFields(commonFields, requiredFields = []) {
      return requiredFields.filter((item) => !commonFields.includes(item.field));
    },

    handleFocusSelect() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleFocusOutSelect() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.remove('custom-input-group');
    },

    selectCurrencyHandler(selectedOption) {
      this.formData.account_currency = selectedOption.currency;
    },

    handleCurrencyFocusSelect() {
      let inpGroupEl = document.getElementById('custom-input-group-currency');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleCurrencyFocusOutSelect() {
      let inpGroupEl = document.getElementById('custom-input-group-currency');
      inpGroupEl.classList.remove('custom-input-group');
    },

    async recipientSubmitHandler() {
      try {
        this.isLoading = true;

        if (this.formData.mobile_number.includes(this.selectedCountryMobileCode)) {
          this.formData.mobile_number = this.formData.mobile_number.slice(this.selectedCountryMobileCode.length);
        }

        this.formData.mobile_number = this.selectedCountryMobileCode + this.formData.mobile_number;

        if (this.isTaxIdRequired) {
          this.formData.recipient_identity_value = this.recipient_identity_value;
          this.formData.recipient_identity_type = this.recipient_identity_type;
        }

        const response = await this.recipientAc(this.formData);

        if (response.statusCode === 200) {
          const sToken = response.data.sessionToken;
          const recipient = response.data.recipient;

          const linked = await this.recpientLinkTxAc({
            recipientKey: recipient.recipient_key
          });

          if (linked.statusCode === 200 && linked.data.recipientStatus) {
            this.$store.commit('SET_RECIPIENT_INFO', recipient);

            this.$router.push({
              path: '/confirm',
              query: { session_token: sToken }
            });
          } else if (!linked.data.recipientStatus) {
            this.isLoading = false;
            toast.error(
              'We are having problem to send money to this recipient, please contact support or select other recipient!',
              {
                position: toast.POSITION.BOTTOM_CENTER,
                icon: false,
                closeButton: false,
                hideProgressBar: true,
                toastClassName: 'text-danger text-center',
                // bodyClassName: 'w-100',
                autoClose: 5000,
                onClose: () => {
                  this.$router.push({
                    path: '/recipient/list',
                    query: { session_token: sToken }
                  });
                }
              }
            );
          }
        }
      } catch (err) {
        this.isLoading = false;

        toast.error(
          'We are having problem to send money to this recipient, please contact support or select other recipient!',
          {
            position: toast.POSITION.BOTTOM_CENTER,
            icon: false,
            closeButton: false,
            hideProgressBar: true,
            toastClassName: 'text-danger text-center',
            // bodyClassName: 'w-100',
            autoClose: 5000
          }
        );
      }
    },
    async extractToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const token = queryParams.get('session_token');
        await this.tokenAc('');
        await this.sessionTokenAc(token);
      }
    }
  },

  watch: {
    'formData.recipient_category': function (val) {
      this.fields = [];
      this.selectedCountry = null;
      this.formData = {
        recipient_email: '',
        bank_country: '',
        account_currency: this.selectedCurrency.currency,
        first_name: '',
        last_name: '',
        bank_account_number: '',
        account_routing_number: '',
        bank_swift_code: 'SWIFT-BIC',
        account_type: 'IBAN',
        relationship: 'SELF',
        dob: '',
        mobile_number: '',
        receiving_institution_id: ''
      };
      (this.isTaxIdRequired = false), (this.recipient_identity_type = ''), (this.recipient_identity_value = '');
      // other
      if (!val) {
        this.formData.recipient_category = false;
        this.formData.recipient_email = '';
        this.formData.first_name = '';
        this.formData.last_name = '';
        this.formData.dob = '';
        this.formData.mobile_number = '';
        this.formData.bank_country = '';
        this.formData.relationship = 'OTHER';
        this.selectedCountry = null;
      } else {
        // self
        this.formData.recipient_category = true;
        this.formData.relationship = 'SELF';
        this.formData.bank_country = '';
        this.formData.recipient_email = this.$store.state.userDetails.email;
        this.formData.first_name = this.$store.state.userDetails?.first_name || '';
        this.formData.last_name = this.$store.state.userDetails?.last_name || '';
        this.formData.dob = this.$store.state.userDetails?.dob || '';
        this.formData.mobile_number = this.$store.state.userDetails?.mobile_number || '';
      }
    },

    'formData.recipient_email': function (value) {
      if (value && !validEmail(value)) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },
    'formData.mobile_number': function (value) {
      if (!value) {
        this.isValidMobile = false;
        return;
      }
      const ctryCode = this.selectedCountry?.iso2;
      const isValid = isValidNumber(value, ctryCode);

      if (!isValid) {
        this.isValidMobile = true;
      } else {
        this.isValidMobile = false;
      }
    },
    'formData.account_routing_number': function (value) {
      this.formData.account_routing_number = value.replace(/[^a-zA-Z0-9]/g, '');
    }
  },
  async mounted() {
    this.isLoading = true;

    const url = window.location;
    await this.extractToken(url);

    await this.userDetailsAc();
    await this.fetchCurrencyAc();
    this.selectedCurrency = this.$store.state.selectedCurrency;
    const recipientCtyList = await this.recipientCtryListAc({
      currency: this.selectedCurrency.currency
    });

    this.countryWithCodes = countryCodesList.customList('countryCode', ' +{countryCallingCode}');

    this.countryList = (recipientCtyList.data || []).sort((a, b) => (a.name < b.name ? 1 : -1));

    this.currencyList = this.getCurrency.sort((a, b) => (a.currency > b.currency ? 1 : -1));

    this.formData.recipient_email = this.$store.state.userDetails?.email || '';
    this.formData.first_name = this.$store.state.userDetails?.first_name || '';
    this.formData.last_name = this.$store.state.userDetails?.last_name || '';
    this.formData.dob = this.$store.state.userDetails?.dob || '';
    this.formData.mobile_number = this.$store.state.userDetails?.mobile_number || '';

    this.formData.account_currency = this.selectedCurrency.currency;

    this.isLoading = false;
  }
};
</script>

<style scoped>
.form-control,
select,
.custom-input-datepicker {
  padding: 0.75rem 0.75rem;
  background-color: #f3f3f3 !important;
  color: #2e693d !important;
  outline: none;
}

input:focus,
select:focus {
  box-shadow: none !important;
  border-color: #359f4e;
}

.custom-input-color {
  color: #2e693d !important;
  font-weight: normal;
}

.custom-input-group {
  border: 1px solid #359f4e !important;
  border-radius: 6px;
  box-shadow: none !important;
}
</style>

<style>
.multiselect__tags,
.multiselect__single,
.multiselect__input {
  border: none;
  line-height: initial;
  color: #2e693d;
  background-color: transparent !important;
}

.toggle-switch-button {
  background-color: #ffd0d0 !important;
  border: 1px solid #e4e9f0;
  padding: 0px 0.2rem;
  height: 1.7rem;
  margin: 0px 5px;
  top: -4px;
}

.toggle-switch-button.Active {
  background-color: #008b24 !important;
  text-align: left;
  color: white;
  top: -4px;
}

.toggle-switch-button.Active .switch-button-label {
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 20px;
}

.toggle-switch-button .switch-button-max-label {
  margin-left: 20px;
  margin-right: 4px;
  opacity: 0;
}

.toggle-switch-button .switch-button-knob {
  height: 25px;
  width: 23px;
  border: 1px solid #e4e9f0;
  top: 0px !important;
}

.toggle-switch-button .switch-button-label {
  margin-top: 4px;
  margin-left: 22px;
  margin-right: 0px;
  display: inline-block;
  position: absolute;
  opacity: 1;
}
</style>
