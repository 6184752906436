<template>
  <div class="d-flex align-items-center justify-content-center vh-100 mb-0 p-0">
    <div class="text-center" style="margin: auto">
      <img
        alt="Triple-A logo"
        src="@/assets/tripleA.png"
        class="img-fluid mb-3"
        width="200"
      />
      <p class="fw-bold">
        Please wait, your request is being processed. This may take a few
        seconds.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'KYCVerificationSection',
  data() {
    return {
      confirmTxPayload: {}
    };
  },
  methods: {
    ...mapActions([
      'txConfirmAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'fetchKycStatus',
      'fetchTxByIDAc',
      'fetchTxAc'
    ]),

    async extractURLToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const reference = queryParams.get('ref');
        const txId = queryParams.get('txn_id');
        const cb = queryParams.get('callback') || '';
        const onChangeCb = queryParams.get('onChangeCallback') || '';

        this.$store.commit('SET_REFERENCE_KEY', reference);
        this.$store.commit('SET_TX_ID', txId);

        this.$store.commit('SET_CALLBACK', cb);
        this.$store.commit('SET_ONCHANGECALLBACK', onChangeCb);

        const token = queryParams.get('session_token');

        await this.tokenAc('');
        await this.sessionTokenAc(token);
      }
    }
  },
  computed: {
    ...mapGetters(['getSessionToken'])
  },
  async mounted() {
    const header = document.getElementById('custom-header');
    header.classList.add('d-none');

    const url = window.location;
    await this.extractURLToken(url);

    try {
      const result = await this.fetchKycStatus();

      if (result.statusCode !== 200) {
        this.$router.push({
          path: '/confirm',
          query: { session_token: this.getSessionToken }
        });
        return;
      }

      const response = await this.fetchTxByIDAc();

      if (response.statusCode === 200) {
        const tx = response.data.transaction;

        this.confirmTxPayload = {
          amount_in: tx.amount_in,
          amount_in_currency: tx.amount_in_asset,
          amount_out: tx.amount_out,
          amount_out_currency: tx.amount_out_asset,
          amount_fee: tx.amount_fee,
          indicative_crypto_amt: tx.indicative_crypto_amt
        };

        this.$store.commit('SET_AMOUNT_INFO', this.confirmTxPayload);

        const confirmTxResponse = await this.txConfirmAc(this.confirmTxPayload);

        if (confirmTxResponse.statusCode === 200) {
          const sToken = confirmTxResponse.sessionToken;

          this.$router.push({
            path: '/sep24/transaction/more_info',
            query: { session_token: sToken }
          });
        }
      }
    } catch (err) {
      console.error(err);
      this.$router.push({
        path: '/confirm',
        query: { session_token: this.getSessionToken }
      });
    }
  },

  async unmounted() {
    const header = document.getElementById('custom-header');
    header.classList.remove('d-none');

    this.$store.state.referenceKey &&
      (await new Promise((resolve) => setTimeout(resolve, 6500)));
  }
};
</script>

<style scoped></style>
