exports.requiredFields = {
  PHL: [
    {
      field: 'address'
    },
    {
      field: 'city'
    },
    {
      field: 'province_state'
    },
    {
      field: 'zip_code'
    },
    //   {
    //     field: 'mobile_number'
    //   },

    {
      field: 'identification_type'
    },
    {
      field: 'identification_number'
    }
  ],
  COL: [
    {
      field: 'address'
    },
    {
      field: 'bank_account_type'
    },
    {
      field: 'identification_type'
    },
    {
      field: 'identification_number'
    }
  ],
  USA: [
    {
      field: 'address'
    },
    {
      field: 'city'
    },
    {
      field: 'province_state'
    },
    {
      field: 'zip_code'
    }
  ],
  MEX: [
    {
      field: 'bank_account_type'
    },
    {
      field: 'identification_type'
    },
    {
      field: 'identification_number'
    }
  ]
};
