<template>
  <div class="container mt-3">
    <TheHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { TheHeader },
  computed: {
    ...mapGetters(['getSessionData', 'getToken', 'getSessionToken'])
  },
  methods: {
    ...mapActions([
      'tokenAc',
      'sessionTokenAc',
      'sessionAc',
      'errorAc',
      'fetchTxAc',
      'fetchAssetsAc'
    ]),

    async extractURLToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('callback')) {
        const cb = queryParams.get('callback') || '';
        this.$store.commit('SET_CALLBACK', cb);
      }

      if (queryParams.has('on_change_callback')) {
        const onChangeCb = queryParams.get('on_change_callback') || '';
        this.$store.commit('SET_ONCHANGECALLBACK', onChangeCb);
      }

      if (queryParams.has('token')) {
        const token = queryParams.get('token') || '';
        await this.tokenAc(token);
      } else if (queryParams.has('session_token')) {
        const sToken = queryParams.get('session_token') || '';
        await this.sessionTokenAc(sToken);
      }
    },

    async handleSession() {
      try {
        await this.sessionAc(this.getToken);

        if (Object.keys(this.getSessionData).length) {
          const sToken = this.getSessionData.sessionToken;

          await this.sessionTokenAc(sToken);
          await this.tokenAc('');
        }
      } catch (err) {
        console.error(err);
        const statusCode = (err.response && err.response.status) || 500;
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message;
        await this.errorAc({ statusCode, message });
        this.$router.push({ path: '/error', query: { statusCode } });
      }
    },

    async handleFetchTx() {
      try {
        const response = await this.fetchTxAc();

        if (response.statusCode === 200) {
          const tx = response.data.transaction;

          this.$store.commit('SET_TRANSACTION_INFO', tx);

          if (
            tx.status === 'incomplete' &&
            !this.$store.state.referenceKey &&
            this.$store.state.kycData?.status !== 'accepted'
          ) {
            this.$router.push({
              path: '/start',
              query: { session_token: this.getSessionToken }
            });
          } else if (
            this.$store.state.kycData &&
            this.$store.state.kycData.status === 'declined'
          ) {
            this.$router.push({
              path: '/confirm',
              query: { session_token: this.getSessionToken }
            });
          } else if (tx.status !== 'incomplete') {
            this.$router.push({
              path: '/sep24/transaction/more_info',
              query: {
                session_token: this.getSessionToken
              }
            });
          }
        }
      } catch (err) {
        console.error(err);
        const statusCode = (err.response && err.response.status) || 500;
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message;
        await this.errorAc({ statusCode, message });
        this.$router.push({ path: '/error', query: { statusCode } });
      }
    }
  },

  async mounted() {
    const url = window.location;

    await this.extractURLToken(url);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (this.$store.state.referenceKey) {
      await new Promise((resolve) => setTimeout(resolve, 6500));
    } else {
      !this.getSessionToken && await this.handleSession();
    }

    await this.handleFetchTx();
  }
};
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fw-bold {
  font-weight: 600 !important;
}

.btn-success {
  background-color: #008b24;
  padding: 0.8rem;
  color: #fff;
  text-transform: capitalize;
  border: none;
}

.btn-success:hover {
  background-color: #008b24;
  color: #fff;
  border: none;
}

.multiselect__option--highlight {
  background: #008b24 !important;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
  .card {
    width: 100% !important;
  }

  .justify-content-center {
    justify-content: start !important;
  }

  .Toastify__toast-container {
    width: 100% !important;
  }
}

@media (min-width: 991px) {
  .Toastify__toast-container {
    width: 41% !important;
  }
}
</style>
