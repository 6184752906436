export default {
  getToken(state) {
    return state.token;
  },

  getSessionToken(state) {
    return state.sessionToken;
  },

  getSessionData(state) {
    return state.session;
  },

  getTransaction(state) {
    return state.transaction;
  },

  getExchangeRate(state) {
    return state.exchangeRate;
  },

  getTxDetails(state) {
    return state.txDetails;
  },

  getAmountInfo(state) {
    return state.txDetails.amount;
  },

  getUserDetails(state) {
    return state.userDetails;
  },

  getRecipient(state) {
    return state.userDetails.recipients || [];
  },

  getAssets(state) {
    return state.assets;
  },

  getKycURL(state) {
    return state.kycUrl;
  },

  getUserCurrency(state) {
    return state.userDetails?.currency || null;
  },

  getCallback(state) {
    return state.callback;
  },

  getOnChangeCallback(state) {
    return state.onChangeCallback;
  },

  getError(state) {
    return state.error;
  },

  getBaseRate(state) {
    return state.baseExchangeRate;
  },

  getCurrency(state) {
    return state.currency;
  },
  getBankList(state) {
    return state.bankList;
  },
};
