import Welcome from '../pages/Welcome.vue';
import Session from '../pages/Session.vue';
// import Start from '../pages/Start.vue';
import Start from '../pages/StartUpdate.vue';
import Kyc from '../pages/Kyc.vue';
import Recipient from '../pages/Recipient.vue';
import Confirm from '../pages/Confirm.vue';
import MoreInfo from '../pages/MoreInfo.vue';
import Error from '../pages/Error.vue';
import NotFound from '../pages/NotFound.vue';
import AddRecipient from '../pages/AddRecipient.vue';
import KycVerification from '../pages/KYCVerification.vue';
import UpdateRecipient from '../pages/UpdateRecipient.vue';

export default [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    props: (route) => ({
      token: route.query.token || route.query.sessionToken
    })
  },
  {
    path: '/session',
    name: 'session',
    component: Session
  },
  {
    path: '/start',
    name: 'start',
    component: Start
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: Kyc
  },
  {
    path: '/recipient/list',
    name: 'recipient/list',
    component: Recipient
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/sep24/transaction/more_info',
    name: 'more_info',
    component: MoreInfo
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/recipient/add',
    name: 'recipient/add',
    component: AddRecipient
  },
  {
    path: '/recipient/update',
    name: 'recipient/update',
    component: UpdateRecipient
  },
  {
    path: '/kyc/verify',
    name: 'kyc/verify',
    component: KycVerification
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
];
