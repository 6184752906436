import { createApp } from 'vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';

import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';

const app = createApp(App);

app.use(router);
app.use(store);

app.use(Vue3Toasity, {
  containerClassName: 'container'
});

app.component('VueDatePicker', VueDatePicker);

app.mount('#app');
