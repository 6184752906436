<template class="mb-5">
  <Loader v-if="isLoading" />
  <div class="mt-4 mb-5" else>
    <div class="d-flex justify-content-center">
      <p class="text-success fw-bold fs-5 mb-4">
        Send crypto to your bank account
      </p>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card w-50" style="">
        <div class="card-body">
          <form @submit.prevent="transactionSubmitHandler">
            <div class="mb-0">
              <div class="input-group mb-0 p-0" id="custom-crypto-input">
                <div class="form-floating mb-0">
                  <input
                    type="number"
                    min="0"
                    step="any"
                    class="form-control shadow-none bg-opacity-75"
                    id="amount-in"
                    v-model.number="formData.amount_in"
                    required
                    placeholder="Amount In"
                    @keyup="cryptoAmountChangeHandler"
                    @focus="handleFocusCrypto"
                    @focusout="handleFocusOutCrypto"
                  />
                  <label for="amount-in">You withdraw</label>
                </div>
                <span
                  class="input-group-text p-0"
                  id="crypto-currency-list"
                  v-if="cryptoCurrencyList.length"
                >
                  <multiselect
                    class="crypto"
                    v-model="selectedCrypto"
                    placeholder="Select Currency"
                    :options="cryptoCurrencyList"
                    :multiple="false"
                    track-by="name"
                    :custom-label="customCryptoLabel"
                    :show-labels="false"
                  >
                    <template v-slot:singleLabel="{ option }">
                      <USDC size="30" class="me-2" />
                      <span class="crypto-name">{{ option.name }}</span>
                    </template>
                  </multiselect>
                </span>
              </div>
            </div>

            <div class="vertical-steps">
              <div class="step">
                <div class="plus-sign">+</div>
                <div class="content">
                  Transfer fee =
                  <span class=""
                    >{{ getExchangeRate.fee }}
                    {{ formData.amount_out_currency }}</span
                  >
                </div>
              </div>
              <div class="step">
                <div class="content">
                  Exchange rate {{ formData.amount_in_currency }}/{{
                    formData.amount_out_currency
                  }}
                  =
                  <span class=""
                    >{{ exchangeAmt.toFixed(2) }} (indicative)</span
                  >
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="input-group mb-0" id="custom-input-group">
                <div class="form-floating">
                  <input
                    type="number"
                    min="0"
                    step="any"
                    class="form-control shadow-none bg-opacity-75"
                    id="amount_out"
                    placeholder="Amount Out"
                    aria-label="Amount out"
                    @focus="handleFocus"
                    @focusout="handleFocusOut"
                    @keyup="fiatAmountChangeHandler"
                    v-model.number="formData.amount_out"
                    aria-describedby="currency-list"
                    required
                  />
                  <label for="amount_out">You get</label>
                </div>

                <span class="input-group-text p-1" id="currency-list">
                  <multiselect
                    v-model="selectedCurrency"
                    placeholder="Select Currency"
                    :options="currencyList"
                    @select="selectCurrencyHandler"
                    @open="handleFocus"
                    @close="handleFocusOut"
                    :multiple="false"
                    track-by="currency"
                    :custom-label="customCurrencyLabel"
                    :show-labels="false"
                    :allow-empty="false"
                  >
                    <template v-slot:singleLabel="{ option }">
                      <img
                        :src="
                          option.icon_name &&
                          getFlag(option.icon_name.toLowerCase())
                        "
                        width="28"
                        class="rounded-circle img-fluid me-2"
                      />
                      <span class="currency-name">{{ option.currency }}</span>
                    </template>
                    <template v-slot="{ option }">
                      <div class="option__desc">
                        <i class="bi bi-xbox option__image"></i>
                        <span class="option__title">{{ option.emoji }}</span>
                        <span class="option__title">{{
                          option.currency_name
                        }}</span>
                        <span class="option__small">{{ option.currency }}</span>
                      </div>
                    </template>
                  </multiselect>
                </span>
              </div>
              <div class="text-danger pt-2" v-if="!isAmountValid">
                <span>{{ currencyType }}</span> amount must be a valid number
              </div>
              <div class="text-danger pt-2" v-if="isCryptoAmountExceed">
                Withdraw amount cannot exceed 10,000 USDC
              </div>
              <div class="text-danger pt-2" v-if="this.getError.isError">
                {{ this.getError.message }}
              </div>
              <div class="text-danger pt-2" v-if="!isValidFiatAmount">
                {{
                  `The minimum value you can receive ${(
                    USDC_BASE_AMOUNT * exchangeAmt
                  ).toFixed(2)} `
                }}
                {{ formData.amount_out_currency }}
              </div>
              <div class="text-danger pt-2" v-if="!isValidUSDCAmount">
                {{
                  `Withdraw amount must be greater than or equal to ${USDC_BASE_AMOUNT} USDC`
                }}
              </div>
            </div>

            <div class="mb-3 d-none">
              <div class="d-flex justify-content-between">
                <div>
                  <small class="text-muted">Total to pay</small>
                </div>
                <div>
                  <h6 class="fw-bold">
                    {{ getExchangeRate.total_to_pay }}
                    {{ getExchangeRate.send_currency }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="mb-3 pt-2">
              <button
                type="submit"
                class="btn btn-success w-100"
                :class="{
                  disabled:
                    !formDataValidation ||
                    !isAmountValid ||
                    this.getError.isError ||
                    isCryptoAmountExceed ||
                    !isValidFiatAmount ||
                    !isValidUSDCAmount
                }"
                :disabled="
                  !formDataValidation ||
                  !isAmountValid ||
                  this.getError.isError ||
                  isCryptoAmountExceed ||
                  !isValidFiatAmount ||
                  !isValidUSDCAmount
                "
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Loader from '../components/Loader.vue';
import multiselect from 'vue-multiselect';
// import CountryFlag from 'vue-country-flag-next';

import { USDC } from 'vue-crypto-icons';

// import { currencyLists } from '../data/currencyList.js';

import { validate } from '../helpers/validateInputData.js';
import { toast } from 'vue3-toastify';

export default {
  name: 'StartSection',
  data() {
    return {
      USDC_BASE_AMOUNT: 100,
      formData: {
        amount_in: 0,
        amount_in_currency: 'USDC',
        amount_out: 0,
        amount_out_currency: '',
        indicative_crypto_amt: 0,
        amount_fee: '0'
      },
      isLoading: false,
      isAmountValid: true,
      isCryptoAmountExceed: false,
      isValidFiatAmount: true,
      isValidUSDCAmount: true,
      exchangeAmt: 0,
      selectedCurrency: {},
      currencyList: [],
      cryptoCurrencyList: [],
      selectedCrypto: {},
      currencyInfo: {},
      currencyType: ''
    };
  },
  components: { Loader, multiselect, USDC },
  methods: {
    ...mapActions([
      'txStartAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'calculateUpdateAc',
      'calculateAc',
      'sessionAc',
      'userDetailsAc',
      'createTxAc',
      'getBaseExchangeRate',
      'fetchAssetsAc',
      'fetchCurrencyAc',
      'resetErrorsAction'
    ]),

    async fiatAmountChangeHandler() {
      // convert number
      this.isAmountValid = false;

      const amount = Number(this.formData.amount_out);

      this.$store.commit('RESET_ERROR');

      // check amount negative or not number
      if (isNaN(amount) || amount <= 0) {
        this.currencyType = 'Fiat';
        this.isAmountValid = false;
        return;
      }

      this.isAmountValid = true;
      this.isValidFiatAmount = true;
      this.isValidUSDCAmount = true;

      amount && (await this.fetchFiatExchangeRate());
    },

    async cryptoAmountChangeHandler() {
      this.isAmountValid = false;
      this.isValidUSDCAmount = true;
      this.isValidFiatAmount = true;

      // convert number
      const amount = Number(this.formData.amount_in);

      this.$store.commit('RESET_ERROR');

      // check amount negative or not number
      if (isNaN(amount) || amount <= 0) {
        this.currencyType = 'Crypto';
        this.isAmountValid = false;
        return;
      }

      // check amount exceeds 10000 USDC
      if (amount >= 10000) {
        this.isCryptoAmountExceed = true;
        this.isAmountValid = true;
      } else {
        this.isCryptoAmountExceed = false;
        this.isAmountValid = true;

        amount && (await this.fetchCryptoExchangeRate());
      }
    },

    getFlag(iso2) {
      return require(`@/assets/flags/${iso2}.svg`);
    },

    handleFocus() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleFocusCrypto() {
      let inpGroupEl = document.getElementById('custom-crypto-input');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleFocusOut() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.remove('custom-input-group');
    },
    handleFocusOutCrypto() {
      let inpGroupEl = document.getElementById('custom-crypto-input');
      inpGroupEl.classList.remove('custom-input-group');
    },

    customCurrencyLabel(option) {
      return `${option.emoji} | ${option.currency}`;
    },

    customCryptoLabel(option) {
      return `${option.name} ${option.emoji}`;
    },

    async selectCurrencyHandler(selectedOption) {
      this.isValidUSDCAmount = true;
      this.isValidFiatAmount = true;

      this.currencyInfo = selectedOption;
      this.formData.amount_out_currency = selectedOption.currency;

      if (!this.formData.amount_out) {
        this.isValidAmount = false;
        return;
      }

      await this.fetchFiatExchangeRate();
    },

    async fetchFiatExchangeRate() {
      try {
        // Check for update every 2 seconds
        await new Promise((resolve) => setTimeout(resolve, 800));

        if (!this.formData.amount_out) {
          this.isAmountValid = false;
          return;
        }

        this.isLoading = true;

        const payload = {
          currency: this.currencyInfo.currency,
          amount: this.formData.amount_out
        };

        await this.calculateAc(payload);

        this.formData.indicative_crypto_amt =
          this.getExchangeRate.indicative_crypto_amt || 0;
        this.exchangeAmt = this.getExchangeRate.exchange_rate;
        this.formData.amount_in = this.getExchangeRate.total_to_pay;
        this.formData.amount_fee = this.getExchangeRate.fee;

        this.isLoading = false;
        if (this.formData.amount_in < this.USDC_BASE_AMOUNT) {
          // this.isAmountValid = false;
          this.isValidFiatAmount = false;
          return;
        }
        this.isValidFiatAmount = true;
      } catch (err) {
        this.isLoading = false;

        console.error(err);

        this.isValidUSDCAmount = false;
      }
    },

    async fetchCryptoExchangeRate() {
      try {
        // Check for update every 2 seconds
        await new Promise((resolve) => setTimeout(resolve, 800));

        if (!this.formData.amount_in) {
          this.isAmountValid = false;
          return;
        }

        this.isLoading = true;

        const payload = {
          fiat_currency: this.currencyInfo.currency,
          crypto_amount: this.formData.amount_in,
          crypto_currency: 'USDC'
        };

        await this.calculateUpdateAc(payload);

        this.formData.indicative_crypto_amt =
          this.getExchangeRate.crypto_amount || 0;
        this.exchangeAmt = this.getExchangeRate.exchange_rate;
        this.formData.amount_out = this.getExchangeRate.receive_fiat_amount;
        this.formData.amount_fee = this.getExchangeRate.fee;

        this.isLoading = false;
        // this.isValidUSDCAmount = true;

        if (
          this.formData.amount_in > 0 &&
          this.formData.amount_in < this.USDC_BASE_AMOUNT
        ) {
          this.isValidUSDCAmount = false;
          return;
        }
      } catch (err) {
        this.isLoading = false;

        console.error(err);

        this.isValidUSDCAmount = false;
      }
    },

    async transactionSubmitHandler() {
      try {
        this.isLoading = true;

        const { isKycNeed, sessionToken, accountBalanceDetails } =
          this.getSessionData;
        const accountBal = Number(accountBalanceDetails.balance);
        const amountIn = Number(this.formData.amount_in);

        if (amountIn > 0 && amountIn < 100) {
          toast.error(
            'Withdraw amount must be greater than or equal to 100 USDC',
            {
              position: toast.POSITION.BOTTOM_CENTER,
              icon: false,
              closeButton: false,
              hideProgressBar: true,
              toastClassName: 'text-danger text-center',
              autoClose: 3000
            }
          );
          this.isLoading = false;
          return;
        }

        if (amountIn > 10000) {
          toast.error(`Withdraw amount cannot exceed 10,000 USDC.`, {
            position: toast.POSITION.BOTTOM_CENTER,
            icon: false,
            closeButton: false,
            hideProgressBar: true,
            toastClassName: 'text-danger text-center',
            autoClose: 3000
          });
          this.isLoading = false;
          return;
        }

        // check stellar amount
        if (amountIn >= accountBal) {
          toast.error(
            `Your wallet USDC balance is too low to complete this transaction.`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              icon: false,
              closeButton: false,
              hideProgressBar: true,
              toastClassName: 'text-danger text-center',
              autoClose: 3000
            }
          );
          this.isLoading = false;
          return;
        }
        this.$store.commit('SET_AMOUNT_INFO', this.formData);
        this.$store.commit('SET_CURRENCY', this.selectedCurrency);

        await this.createTxAc();

        if (isKycNeed) {
          this.$router.push({
            path: '/kyc',
            query: { session_token: sessionToken }
          });
        } else {
          if (this.getRecipient.length) {
            this.$router.push({
              path: '/recipient/list',
              query: { session_token: sessionToken }
            });
          } else {
            this.$router.push({
              path: '/recipient/add',
              query: { session_token: sessionToken }
            });
          }
        }
      } catch (err) {
        this.isLoading = false;

        console.error(err);
        const statusCode = (err.response && err.response.status) || 500;
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message;
        await this.errorAc({ statusCode, message });
        this.$router.push({ path: '/error', query: { statusCode } });
      }
    },

    async extractURLToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const sToken = queryParams.get('session_token');
        await this.tokenAc('');
        await this.sessionTokenAc(sToken);
      }
    }
  },
  computed: {
    ...mapGetters([
      'getExchangeRate',
      'getToken',
      'getSessionToken',
      'getSessionData',
      'getRecipient',
      'getAssets',
      'getUserCurrency',
      'getError',
      'getBaseRate',
      'getCurrency'
    ]),
    formDataValidation() {
      const payload = { ...this.formData };
      if (!validate(payload)) {
        return false;
      }
      return true;
    }
  },
  watch: {},
  async mounted() {
    try {
      this.isLoading = true;

      const url = window.location;
      await this.extractURLToken(url);
      await this.fetchAssetsAc();
      await this.fetchCurrencyAc();

      await this.userDetailsAc();

      this.currencyList = this.getCurrency;

      this.cryptoCurrencyList = this.getAssets;

      this.selectedCrypto = this.cryptoCurrencyList[0];

      this.formData.amount_in_currency = this.selectedCrypto?.name || 'USDC';
      this.formData.amount_in = this.USDC_BASE_AMOUNT;

      if (this.getUserCurrency) {
        this.selectedCurrency =
          this.currencyList.find((ccy) => {
            return ccy.currency === this.getUserCurrency.currencyCode;
          }) || this.currencyList[0];

        this.currencyInfo = this.selectedCurrency;
        this.formData.amount_out_currency =
          this.selectedCurrency?.currency || '';
      } else {
        this.selectedCurrency = this.currencyList[0];

        this.currencyInfo = this.currencyList[0];
        this.formData.amount_out_currency = this.selectedCurrency.currency;
      }

      await this.fetchCryptoExchangeRate();

      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;

      console.error(err);
      const statusCode = (err.response && err.response.status) || 500;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message;
      await this.errorAc({ statusCode, message });
      this.$router.push({ path: '/error', query: { statusCode } });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
#currency-list {
  background: #f3f3f3 !important;
  border-left-color: #f3f3f3;
}
#crypto-currency-list {
  background: #f3f3f3 !important;
  border-left-color: #f3f3f3;
}

#amount_out {
  background: #f3f3f3;
  border-right-color: #f3f3f3;
}

#amount-in {
  background: #f3f3f3;
  border-right-color: #f3f3f3;
}

#input-currency {
  background-color: #f3f3f3 !important;
  text-align: center;
}

.form-control {
  color: #2e693d !important;
}

.form-control:focus {
  border: 1px solid #f3f3f3 !important;
  box-shadow: none !important;
}

.custom-input-group {
  border: 1px solid #359f4e !important;
  border-radius: 6px;
  box-shadow: none !important;
}
.fi {
  background-size: auto;
}
.custom-icon-style {
  border-radius: 50%;
  font-size: 1.4rem;
}

.vertical-steps .step:first-child {
  padding-top: 30px;
}

.vertical-steps .step:last-child {
  padding-bottom: 15px;
}

.vertical-steps .step {
  position: relative;
  padding-left: 40px;
}

.vertical-steps .step::after {
  content: '';
  display: table;
  width: 2px;
  height: 100%;
  background-color: #eee5e9;
  position: absolute;
  left: 17px;
  top: 0px;
}

.vertical-steps .step .content {
  position: relative;
  padding-bottom: 16px;
  color: #6f8691;
  font-size: 0.9em;
}

.vertical-steps .step .content::before {
  content: '';
  display: table;
  border: 10px solid #eee5e9;
  position: absolute;
  border-radius: 10px;
  z-index: 1;
  left: -32px;
  top: 0px;
}

.vertical-steps .step .plus-sign {
  display: table;
  position: absolute;
  z-index: 2;
  top: 29px;
  left: 13px;
  font-weight: 800;
  color: #359f4e;
}

.vertical-steps .step.completed .content::before {
  border: 6px solid #2d8426;
}

.vertical-steps .content {
  color: #000000;
  font-size: 1.1rem;
}
</style>

<style>
.multiselect.crypto {
  pointer-events: none;
}

.multiselect.crypto .multiselect__select::before {
  display: none;
}

.multiselect__tags,
.multiselect__single,
.multiselect__input {
  border: none;
  background-color: #f3f3f3 !important;
}

.crypto-name {
  /* margin-left: 1.7rem; */
  margin-right: -22px;
}

.multiselect__element {
  display: block;
  padding: 6px 0px;
}
.currency-name {
  margin-left: 0rem;
}

@media (max-width: 991px) {
  .crypto-name {
    margin-left: 0.5rem;
  }

  .currency-name {
    margin-left: 0.4rem;
    margin-right: -5px;
  }
}

@media (max-width: 340px) {
  .exchange-rate-section {
    display: block !important;
  }
}
</style>
