<template>
  <div class="d-flex justify-content-center" id="custom-header">
    <a href="#" class="link-success">
      <img alt="Triple-A logo" src="@/assets/tripleA.png" class="img-fluid" width="150" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'TheHeader'
};
</script>

<style scoped></style>
