<template>
  <div>
    <h2>Code: 404</h2>
    <h5>Error</h5>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped></style>
