<template>
  <div class="d-flex align-items-center justify-content-center vh-100 mb-0 p-0">
    <div class="text-center" style="margin: auto">
      <img
        alt="Triple-A logo"
        src="@/assets/tripleA.png"
        class="img-fluid mb-4"
        width="200"
      />
      <h6>
        Please close this page and try sending money again. For support, email
        to
        <a
          class="text-success"
          href="mailto:support@triple-a.io"
          >support@triple-a.io</a
        >
        . <br />
        <div class="mt-1 text-uppercase">Thank you!</div>
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorSection',
  data() {
    return {
      message: '',
      statusCode: 500
    };
  },
  mounted() {
    const header = document.getElementById('custom-header');
    header.classList.add('d-none');

    const { errorMessage, errorStatusCode } = this.$store.state;
    this.message = errorMessage;
    this.statusCode = errorStatusCode;
  },
  unmounted() {
    const header = document.getElementById('custom-header');
    header.classList.remove('d-none');
  }
};
</script>

<style scoped>
.text-success {
  color: #2a7f3e;
}
</style>
