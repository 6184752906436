exports.formatter = (datetimeString = '') => {
  if (!datetimeString) return '';
  
  const dateObj = new Date(datetimeString);

  //month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  // Extract the components of the date and time
  const day = dateObj.getDate();
  const month = monthNames[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the result
  const formattedDate = `${day.toString().padStart(2, '0')} ${month} ${year}`;
  const formattedTime = `${hours}:${minutes
    .toString()
    .padStart(2, '0')} ${amPm}`;
  const formattedDateTime = `${formattedDate} | ${formattedTime}`;

  return formattedDateTime;
};
