<template>
  <Loader v-if="isLoading" />
  <div class="mt-4 mb-5" v-else>
    <div class="d-flex justify-content-center">
      <div class="card w-50 border-0 bg-transparent ps-0">
        <div class="card-body">
          <div class="row">
            <div class="col-9 ps-0">
              <div class="row row-cols-1">
                <div class="col mb-2">
                  <h4 class="text-success fw-bold">Select Recipient</h4>
                </div>
              </div>
            </div>
            <div class="col-3 text-end pe-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="#359f4e"
                class="bi bi-plus text-success border bg-transparent"
                viewBox="0 0 16 16"
                @click="addNewRecipientHandler"
              >
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="card w-50">
        <div class="card-body">
          <div class="row row-cols-2 mb-3">
            <div class="col">
              <input
                class="form-control shadow-none"
                type="search"
                placeholder="Account Name"
                v-model="query"
                @keyup="filterRecipientByAccountName"
                aria-label="Search"
              />
            </div>
            <div class="col">
              <div class="text-end">
                <button
                  class="btn rounded-0 ms-2 custom-button-border"
                  @click="filterRecipient('self')"
                  :class="{
                    'btn-success-self': selectedRecipientType === 'self'
                  }"
                >
                  Self
                </button>
                <button
                  class="btn rounded-0 custom-button-border"
                  :class="{
                    'btn-success-other': selectedRecipientType === 'other'
                  }"
                  @click="filterRecipient('other')"
                >
                  Other
                </button>
              </div>
            </div>
          </div>
          <div class="accordion">
            <div
              class="accordion-item border mb-3"
              v-for="recipient in recipients"
              :key="recipient.recipient_key"
            >
              <div class="accordion-header">
                <button
                  class="accordion-button collapsed shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#${recipient.recipient_key}`"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <div class="row row-cols-1">
                    <div class="col">
                      <h5 class="text-success fw-bold">
                        {{ recipient.first_name }} {{ recipient.last_name }}
                      </h5>
                    </div>
                    <div class="col text-muted text-capitalize">
                      Account/IBAN number:
                      <span class="text-success ms-lg-1">{{
                        recipient.bank_account_number
                      }}</span>
                    </div>
                  </div>
                </button>
              </div>

              <div
                :id="recipient.recipient_key"
                class="accordion-collapse collapse"
              >
                <div class="accordion-body border-top">
                  <div class="row mb-1">
                    <div class="col">
                      <div class="row row-cols-1">
                        <div class="col">
                          <small class="text-muted text-capitalize"
                            >Recipient type</small
                          >
                        </div>
                        <div class="col">
                          <h5 class="text-success">
                            {{ recipient.recipient_category }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row row-cols-1">
                        <div class="col">
                          <small class="text-muted text-capitalize"
                            >Bank Country</small
                          >
                        </div>
                        <div class="col">
                          <h5 class="text-success">
                            {{ countryNameByCode(recipient.bank_country) }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col">
                      <div class="row row-cols-1">
                        <div class="col">
                          <small class="text-muted text-capitalize"
                            >SWIFT/BIC code</small
                          >
                        </div>
                        <div class="col">
                          <h5 class="text-success">
                            {{ recipient.bank_swift_code }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row row-cols-1">
                        <div class="col">
                          <small class="text-muted text-capitalize"
                            >Bank Currency</small
                          >
                        </div>
                        <div class="col">
                          <h5 class="text-success">
                            {{ recipient.account_currency }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row row-cols-1">
                    <div class="col">
                      <button
                        class="btn btn-success w-100"
                        @click="
                          confirmRecipiendHandler(recipient.recipient_key)
                        "
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="!recipients.length">
              <div class="accordion-body border">
                <h6 class="text-center">No Recipient Found</h6>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { toast } from 'vue3-toastify';

import { currencies } from '../data/currencies.js';
import Loader from '../components/Loader.vue';

export default {
  name: 'RecipientSection',
  data() {
    return {
      isLoading: false,
      recipients: [],
      selectedRecipientType: 'self',
      query: ''
    };
  },
  components: {
    Loader
  },
  computed: {
    ...mapGetters([
      'getRecipient',
      'getSessionToken',
      'getAmountInfo',
      'getUserDetails'
    ])
  },
  methods: {
    ...mapActions([
      'kycAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'userDetailsAc',
      'recpientLinkTxAc',
      'recipientUpdateAc'
    ]),

    countryNameByCode(code = '') {
      const iso = code.length == 2 ? 'iso2' : 'iso3';
      const country = currencies.find((ctry) => ctry[iso] === code);
      return country?.name || code;
    },

    filterRecipientByAccountName() {
      if (this.query.length) {
        this.recipients = this.getRecipient.filter((recipient) => {
          const recipientName = (
            `${recipient.first_name} ${recipient.last_name}` || ''
          ).toLowerCase();
          const query = this.query.toLowerCase();

          return (
            recipientName.includes(query) &&
            recipient.recipient_category === this.selectedRecipientType
          );
        });
      } else {
        this.recipients = this.getRecipient.filter(
          (recipient) =>
            recipient.recipient_category === this.selectedRecipientType
        );
      }
    },

    addNewRecipientHandler() {
      this.isLoading = true;

      this.$router.push({
        path: '/recipient/add',
        query: { session_token: this.getSessionToken }
      });
    },

    async confirmRecipiendHandler(selectedRecipient) {
      this.isLoading = true;

      const choosedRecipient = this.recipients.find((recipient) => {
        return recipient.recipient_key === selectedRecipient;
      });

      if (
        this.getAmountInfo.amount_out_currency !==
        choosedRecipient.account_currency
      ) {
        toast.error(
          `You selected to send '${this.getAmountInfo.amount_out_currency}',  Please select a recipient who can receive '${this.getAmountInfo.amount_out_currency}' or create new one.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            icon: false,
            closeButton: false,
            hideProgressBar: true,
            toastClassName: 'text-danger text-center',
            // bodyClassName: 'w-100',
            autoClose: 4000
          }
        );
        this.isLoading = false;
        return;
      }

      let isValidRecipient = true;
      const requiredProperties = ['dob', 'mobile_number'];

      for (const key of requiredProperties) {
        if (
          choosedRecipient[key] === null ||
          choosedRecipient[key] === undefined
        ) {
          isValidRecipient = false;
          break;
        }
      }

      if (
        !isValidRecipient &&
        choosedRecipient.recipient_category === 'other'
      ) {
        this.$router.push({
          path: '/recipient/update',
          query: {
            session_token: this.getSessionToken,
            recipient_key: choosedRecipient.recipient_key
          }
        });
        return;
      } else if (!isValidRecipient) {
        const payload = {
          rkey: choosedRecipient.recipient_key,
          dob: this.getUserDetails.dob,
          mobile_number: this.getUserDetails.mobile_number
        };
        await this.recipientUpdateAc(payload);
      }

      try {
        const linked = await this.recpientLinkTxAc({
          recipientKey: selectedRecipient
        });

        if (linked.statusCode === 200 && linked.data.recipientStatus) {
          this.$store.commit('SET_RECIPIENT_INFO', choosedRecipient);
          this.$router.push({
            path: '/confirm',
            query: { session_token: this.getSessionToken }
          });
        } else if (!linked.data.recipientStatus) {
          this.isLoading = false;
          toast.error(
            'We are having problem to send money to this recipient, please contact support or select other recipient!',
            {
              position: toast.POSITION.BOTTOM_CENTER,
              icon: false,
              closeButton: false,
              hideProgressBar: true,
              toastClassName: 'text-danger text-center',
              // bodyClassName: 'w-100',
              autoClose: 5000
            }
          );
        }
      } catch (err) {
        this.isLoading = false;

        console.error(err);
        toast.error(
          'We are having problem to send money to this recipient, please contact support or select other recipient!',
          {
            position: toast.POSITION.BOTTOM_CENTER,
            icon: false,
            closeButton: false,
            hideProgressBar: true,
            toastClassName: 'text-danger text-center',
            // bodyClassName: 'w-100',
            autoClose: 5000
          }
        );
      }
    },

    filterRecipient(recipientType = 'self') {
      this.selectedRecipientType = recipientType;

      this.recipients = this.getRecipient.filter(
        (recipient) => recipient.recipient_category === recipientType
      );
    },

    async extractToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const token = queryParams.get('session_token');
        await this.tokenAc('');
        await this.sessionTokenAc(token);
      }
    }
  },
  async mounted() {
    try {
      this.isLoading = true;

      const url = window.location;
      await this.extractToken(url);
      await this.userDetailsAc();

      this.recipients = this.getRecipient.filter(
        (recipient) =>
          recipient.recipient_category === this.selectedRecipientType
      );

      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;

      console.error(err);
      const statusCode = (err.response && err.response.status) || 500;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message;
      await this.errorAc({ statusCode, message });
      this.$router.push({ path: '/error', query: { statusCode } });
    }
  }
};
</script>

<style scoped>
.accordion-button::after {
  background-image: url('@/assets/right-arrow-backup-2.svg') !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('@/assets/down-arrow-backup-2.svg') !important;
  transform: rotate(0deg);
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
}

svg.bi.bi-plus.text-success.border.bg-transparent {
  cursor: pointer;
}

.form-control {
  background-color: #f3f3f3 !important;
  color: #2e693d !important;
  outline: none;
}

input:focus {
  box-shadow: none !important;
  border-color: #359f4e;
}

.custom-button-border {
  border-top-color: #e4e9f0 !important;
  border-bottom-color: #e4e9f0 !important;
  border-right-color: #e4e9f0 !important;
  border-left-color: #e4e9f0 !important;
}
.btn-success-self {
  background-color: #008b24;
  color: #fff;
  text-transform: capitalize;
  border: 1px solid #e4e9f0 !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.btn-success-other {
  background-color: #008b24;
  color: #fff;
  text-transform: capitalize;
  border: 1px solid #e4e9f0 !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background: none !important;
}

.accordion-button {
  background-color: #f3f3f3;
}
</style>
