<template>
  <Loader v-if="isLoading" />
  <div class="mt-3" v-else>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-success fw-bold fs-5">Transaction status</p>
    </div>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-muted">Details information of your stellar transaction</p>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card w-50">
        <div class="card-body">
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted"> You withdraw</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{
                  transactionInfo?.amount_in?.amount || getAmountInfo.amount_in
                }}
                {{
                  transactionInfo?.amount_in?.asset.split(':')[1] ||
                  getAmountInfo.amount_in_currency
                }}
              </h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted"> Recipient gets </small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{
                  transactionInfo?.amount_out?.amount ||
                  getAmountInfo.amount_out
                }}
                {{
                  transactionInfo?.amount_out?.asset.split(':')[1] ||
                  getAmountInfo.amount_out_currency
                }}
              </h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted"> Fee </small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{
                  transactionInfo?.amount_fee?.amount ||
                  getAmountInfo.amount_fee
                }}
                {{
                  transactionInfo?.amount_out?.asset.split(':')[1] ||
                  getAmountInfo.amount_out_currency
                }}
              </h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted">Transaction type</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{ transactionInfo.kind }}
              </h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted">Transaction ID</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{ transactionInfo.id }}
              </h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted">Transaction status</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{ transactionInfo.status }}
              </h6>
            </div>
          </div>
          <div
            class="row row-cols-1 mb-2"
            v-if="transactionInfo.status !== 'pending_anchor'"
          >
            <div class="col">
              <small class="text-muted">Transaction message</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{ transactionInfo.message }}
              </h6>
            </div>
          </div>
          <div
            class="row row-cols-1 mb-2"
            v-if="transactionInfo.status === 'pending_anchor'"
          >
            <div class="col">
              <small class="text-muted">Transaction message</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">The anchor is processing the transaction</h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-2">
            <div class="col">
              <small class="text-muted">Started</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{ formatterDateTime(transactionInfo.started_at) }}
              </h6>
            </div>
          </div>

          <div
            class="row row-cols-1 mb-2"
            v-if="transactionInfo.status === 'completed'"
          >
            <div class="col">
              <small class="text-muted"> Completed </small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{
                  formatterDateTime(
                    transactionInfo.stellar_transactions[0].created_at
                  )
                }}
              </h6>
            </div>
          </div>

          <div class="row row-cols-1 mb-2" v-if="transactionInfo.memo">
            <div class="col">
              <small class="text-muted">Memo</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">{{ transactionInfo.memo }}</h6>
            </div>
          </div>
          <template v-if="transactionInfo.stellar_transactions?.length">
            <div class="row row-cols-1 mb-2">
              <div class="col">
                <small class="text-muted">Stellar transaction ID</small>
              </div>
              <div class="col">
                <h6 class="fw-bold">
                  {{ transactionInfo.stellar_transactions[0].id }}
                </h6>
              </div>
            </div>
            <div class="row row-cols-1 mb-2">
              <div class="col">
                <small class="text-muted">Destination account</small>
              </div>
              <div class="col">
                <h6 class="fw-bold">
                  {{ transactionInfo.destination_account }}
                </h6>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Loader from '../components/Loader.vue';

import { formatter } from '../helpers/formatDateTime.js';

export default {
  name: 'MoreInfo',
  data() {
    return {
      transactionInfo: {},
      isLoading: false,
      transactionEndStatus: ['pending_external', 'completed', 'error']
    };
  },
  components: { Loader },
  methods: {
    ...mapActions([
      'tokenAc',
      'sessionTokenAc',
      'sessionAc',
      'errorAc',
      'fetchTxAc'
    ]),

    formatterDateTime(date) {
      return formatter(date);
    },

    async sendTransactionDataToOpener(tx) {
      const data = {
        transaction: {
          withdraw_anchor_account: tx.destination_account,
          id: tx.id,
          kind: tx.kind,
          status: tx.status,
          started_at: tx.started_at,
          refunded: tx.refunded,
          from: tx.source_account,
          to: tx.destination_account,
          amount_in: tx.amount_in.amount,
          amount_in_asset: tx.amount_in.asset,
          amount_out: tx.amount_out.amount,
          amount_out_asset: tx.amount_out.asset,
          amount_fee: tx.amount_fee.amount,
          amount_fee_asset: tx.amount_fee.asset,
          withdraw_memo: tx.memo,
          withdraw_memo_type: tx.memo_type
        }
      };

      if (this.getCallback.toLowerCase() === 'postmessage') {
        this.postMessageCallback(data);
      } else if (this.getOnChangeCallback) {
        this.urlCallback(this.getOnChangeCallback, data);
      }
    },

    postMessageCallback(data) {
      let targetWindow;

      if (window.opener != void 0) {
        targetWindow = window.opener;
      } else if (window.parent != void 0) {
        targetWindow = window.parent;
      } else {
        return;
      }

      // Send data to the opener window
      targetWindow.postMessage(data, '*');
    },

    urlCallback(url, data) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .catch((e) => {
          console.error(`POST request to ${url} failed.`, e);
        })
        .then((response) => {
          if (!response.ok) {
            console.error(`POST request to ${url} failed.`, response);
            return;
          }
        });
    },

    async pollingTransaction() {
      if (!this.getSessionToken) {
        this.isLoading = true;
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      this.isLoading = false;
      while (!this.transactionEndStatus.includes(this.transactionInfo.status)) {
        const response = await this.fetchTxAc();

        if (response.statusCode === 200) {
          this.transactionInfo = response.data.transaction;

          this.sendTransactionDataToOpener(this.transactionInfo);
        }

        // Check for update every 2 seconds
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }
    },
    async extractURLToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('token')) {
        const token = queryParams.get('token') || '';

        await this.tokenAc(token);
        await this.sessionAc(this.getToken);
        await new Promise((resolve) => setTimeout(resolve, 1500));
      } else if (queryParams.has('session_token')) {
        const token = queryParams.get('session_token') || '';

        await this.sessionTokenAc(token);
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTxDetails',
      'getSessionToken',
      'getAmountInfo',
      'getCallback',
      'getOnChangeCallback',
      'getToken'
    ])
  },
  async mounted() {
    try {
      const url = window.location;
      await this.extractURLToken(url);

      if (this.getSessionToken) {
        this.isLoading = false;
      }
      await this.pollingTransaction();
    } catch (err) {
      this.isLoading = false;

      console.error(err);
      const statusCode = (err.response && err.response.status) || 500;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message;
      await this.errorAc({ statusCode, message });
      this.$router.push({ path: '/error', query: { statusCode } });
    }
  }
};
</script>

<style scoped></style>
