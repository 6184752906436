<template>
  <div class="d-flex align-items-center justify-content-center vh-100 mb-0 p-0">
    <div class="text-center" style="margin: auto">
      <img
        alt="Triple-A logo"
        src="@/assets/tripleA.png"
        class="img-fluid mb-4"
        width="200"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreetingText',
  mounted() {
    const header = document.getElementById('custom-header');
    header.classList.add('d-none');
  },
  unmounted() {
    const header = document.getElementById('custom-header');
    header.classList.remove('d-none');
  }
};
</script>

<style scoped></style>
