export default {
  SET_TOKEN(state, token = '') {
    state.token = token;
  },

  SET_SESSION_TOKEN(state, token = '') {
    state.sessionToken = token;
  },

  SET_SESSION_DATA(state, data = {}) {
    state.session = data;
  },

  SET_USER_DETAILS(state, data = {}) {
    state.userDetails = data;
  },

  SET_EXCHANGE_RATE(state, data = {}) {
    state.exchangeRate = data;
  },

  SET_BASE_EXCHANGE_RATE(state, data = {}) {
    state.baseExchangeRate = data;
  },

  SET_AMOUNT_INFO(state, data = {}) {
    state.txDetails.amount = data;
  },

  SET_RECIPIENT_INFO(state, data = {}) {
    state.txDetails.recipient = data.recipient;
  },

  SET_TRANSACTION_INFO(state, data = {}) {
    state.txDetails.transaction = data.tx;
  },

  SET_TRANSACTION(state, data = {}) {
    state.transaction = data;
  },

  SET_ASSETS(state, data = {}) {
    state.assets = data;
  },

  SET_CURRENCY(state, data = {}) {
    state.selectedCurrency = data;
  },

  SET_TX_ID(state, data = {}) {
    state.transaction_id = data;
  },

  SET_KYC_DATA(state, data = {}) {
    state.KycData = { status: data.kyc_status };
  },

  SET_KYC_URL(state, data = {}) {
    state.kycUrl = data.kycUrl;
  },

  SET_REFERENCE_KEY(state, data = {}) {
    state.referenceKey = data;
  },

  SET_CALLBACK(state, data = 'postMessage') {
    state.callback = data;
  },

  SET_ONCHANGECALLBACK(state, data = '') {
    state.onChangeCallback = data;
  },

  SET_ERROR(state, { statusCode, message }) {
    state.error.isError = true;
    state.error.message = message;
    state.error.statusCode = statusCode;
  },
  SET_CURRENCIES(state, data) {
    state.currency = data;
  },
  SET_BANK_LIST(state, data = []) {
    state.bankList = data;
  },

  RESET_ERROR(state) {
    state.error.isError = false;
    state.error.message = null;
    state.error.statusCode = null;
  }
};
