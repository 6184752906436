<template>
  <Loader v-if="isLoading" />
  <div class="mt-4 mb-5" v-else>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-success fw-bold fs-5">
        Update recipient bank account details
      </p>
    </div>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-muted">Please fill out all the information below.</p>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card w-50">
        <div class="card-body">
          <form @submit.prevent="recipientUpdateHandler">
            <div class="mb-3">
              <label for="recipient-name" class="form-label"
                >First name of the account holder</label
              >
              <input
                type="text"
                class="form-control"
                id="recipient-name"
                readonly
                v-model="formData.first_name"
              />
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="form-label"
                >Last name of the account holder</label
              >
              <input
                type="text"
                class="form-control"
                id="recipient-name"
                readonly
                v-model="formData.last_name"
              />
            </div>

            <div class="mb-3">
              <label for="recipient-email" class="form-label"
                >Recipient Email</label
              >
              <input
                type="email"
                class="form-control"
                id="recipient-email"
                readonly
                v-model="formData.recipient_email"
              />
            </div>
            <div class="mb-3">
              <label for="recipient-dob" class="form-label"
                >Recipient DOB</label
              >
              <input
                type="date"
                class="form-control"
                id="recipient-dob"
                v-model="formData.dob"
              />
            </div>
            <div class="mb-3">
              <label for="account-number" class="form-label"
                >IBAN Account Number</label
              >
              <input
                type="text"
                class="form-control"
                id="account-number"
                readonly
                v-model="formData.bank_account_number"
              />
            </div>

            <div class="mb-3">
              <label for="swift-code" class="form-label">BIC/SWIFT Code</label>
              <input
                type="text"
                class="form-control"
                id="swift-code"
                readonly
                v-model="formData.account_routing_number"
              />
            </div>
            <div class="mb-3">
              <label for="country" class="form-label">Country</label>
              <span class="input-group-text ps-0" id="custom-input-group">
                <multiselect
                  v-model="selectedCountry"
                  :options="countryList"
                  @select="selectCountryHandler"
                  @open="handleFocusSelect"
                  @close="handleFocusOutSelect"
                  @remove="removeCountry"
                  placeholder=""
                  :multiple="false"
                  track-by="name"
                  :custom-label="customCountryLabel"
                  :show-labels="false"
                  :allow-empty="false"
                  :disabled="true"
                >
                  <template v-slot:singleLabel="{ option }">
                    <span class="">{{ option.name }}</span>
                  </template>
                </multiselect>
              </span>
            </div>
            <div class="mb-3">
              <label for="mobile_number" class="form-label"
                >Recipient Mobile No.</label
              >
              <div class="input-group">
                <span
                  class="input-group-text"
                  :class="{ 'd-none': !this.formData.bank_country }"
                  >{{ selectedCountryMobileCode }}</span
                >
                <input
                  type="text"
                  class="form-control"
                  id="mobile_number"
                  v-model="formData.mobile_number"
                />
              </div>
              <span
                class="text-danger mt-1"
                v-if="!isValidMobile && formData.mobile_number"
                >Invalid mobile number</span
              >
            </div>
            <div class="mb-3">
              <label for="currency" class="form-label">Currency</label>
              <span
                class="input-group-text ps-0"
                id="custom-input-group-currency"
              >
                <multiselect
                  v-model="selectedCurrency"
                  :options="currencyList"
                  @select="selectCurrencyHandler"
                  @open="handleCurrencyFocusSelect"
                  @close="handleCurrencyFocusOutSelect"
                  @remove="removeCurrency"
                  placeholder=""
                  :multiple="false"
                  track-by="name"
                  :custom-label="customCurrencyLabel"
                  :show-labels="false"
                  :allow-empty="false"
                  :disabled="true"
                >
                  <template v-slot:singleLabel="{ option }">
                    <span class="">{{ option.currency }}</span>
                  </template>
                  <template v-slot="{ option }">
                    <div class="option__desc">
                      <i class="bi bi-xbox option__image"></i>
                      <span class="option__title">{{ option.name }}</span>
                      <span class="option__small">{{ option.currency }}</span>
                      <span class="option__small">{{ option.emoji }}</span>
                    </div>
                  </template>
                </multiselect>
              </span>
            </div>
            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-success w-100"
                :class="{ disabled: !isValidMobile }"
                :disabled="!isValidMobile"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import countryCodesList from 'country-codes-list';
import multiselect from 'vue-multiselect';
import { toast } from 'vue3-toastify';
import { isValidNumber } from 'libphonenumber-js';

import { currencies } from '../data/currencies';

import Loader from '../components/Loader.vue';

export default {
  name: 'RecipientUpdateSection',
  data() {
    return {
      formData: {
        recipient_category: true,
        recipient_email: '',
        bank_country: '',
        account_currency: '',
        first_name: '',
        last_name: '',
        bank_account_number: '',
        account_routing_number: '',
        bank_swift_code: 'SWIFT-BIC',
        account_type: 'IBAN',
        relationship: 'SELF',
        dob: '',
        mobile_number: ''
      },
      selectedCountryMobileCode: '',
      isLoading: false,
      isValidMobile: false,
      countryList: [],
      currencyList: [],
      selectedCountry: null,
      selectedCurrency: null,
      countryWithCodes: [],
      recipientKey: ''
    };
  },
  components: {
    Loader,
    multiselect
  },
  computed: {
    ...mapGetters(['getRecipient', 'getUserDetails'])
  },
  methods: {
    ...mapActions([
      'recipientUpdateAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'recpientLinkTxAc',
      'userDetailsAc'
    ]),

    customCountryLabel(option) {
      return `${option.emoji} ${option.name}`;
    },

    customCurrencyLabel(option) {
      return `${option.emoji} ${option.currency}`;
    },

    removeCountry() {
      this.formData.bank_country = '';
      this.selectedCurrency = null;
    },

    removeCurrency() {
      this.formData.account_currency = '';
      this.selectedCountry = null;
    },

    selectCountryHandler(selectedOption) {
      const { iso3, currency, iso2 } = selectedOption;

      this.isValidMobile = false;

      this.selectedCountryMobileCode = (
        this.countryWithCodes[iso2] || ''
      ).trim();

      if (currency !== this.formData.account_currency) {
        toast.error(
          "The selected currency and the country's currency do not match",
          {
            position: toast.POSITION.BOTTOM_CENTER,
            icon: false,
            closeButton: false,
            hideProgressBar: true,
            toastClassName: 'text-danger',
            bodyClassName: 'w-100',
            autoClose: 4500
          }
        );
        this.formData.bank_country = '';
        this.selectedCountry = null;
        return;
      }
      this.formData.bank_country = iso3;
    },

    handleFocusSelect() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleFocusOutSelect() {
      let inpGroupEl = document.getElementById('custom-input-group');
      inpGroupEl.classList.remove('custom-input-group');
    },

    selectCurrencyHandler(selectedOption) {
      this.formData.account_currency = selectedOption.currency;
    },

    handleCurrencyFocusSelect() {
      let inpGroupEl = document.getElementById('custom-input-group-currency');
      inpGroupEl.classList.add('custom-input-group');
    },

    handleCurrencyFocusOutSelect() {
      let inpGroupEl = document.getElementById('custom-input-group-currency');
      inpGroupEl.classList.remove('custom-input-group');
    },

    async recipientUpdateHandler() {
      try {
        this.isLoading = true;

        if (
          this.formData.mobile_number.includes(this.selectedCountryMobileCode)
        ) {
          this.formData.mobile_number = this.formData.mobile_number.slice(
            this.selectedCountryMobileCode.length
          );
        }

        this.formData.mobile_number =
          this.selectedCountryMobileCode + this.formData.mobile_number;
          
        this.formData.rkey = this.recipientKey;

        const response = await this.recipientUpdateAc(this.formData);

        if (response.statusCode === 200) {
          const sToken = response.data.sessionToken;
          const recipient = response.data.recipient;

          const linked = await this.recpientLinkTxAc({
            recipientKey: recipient.recipient_key
          });

          if (linked.statusCode === 200 && linked.data.recipientStatus) {
            this.$store.commit('SET_RECIPIENT_INFO', recipient);

            this.$router.push({
              path: '/confirm',
              query: { session_token: sToken }
            });
          } else if (!linked.data.recipientStatus) {
            this.isLoading = false;
            toast.error(
              'We are having problem to send money to this recipient, please contact support or select other recipient!',
              {
                position: toast.POSITION.BOTTOM_CENTER,
                icon: false,
                closeButton: false,
                hideProgressBar: true,
                toastClassName: 'text-danger text-center',
                // bodyClassName: 'w-100',
                autoClose: 5000,
                onClose: () => {
                  this.$router.push({
                    path: '/recipient/list',
                    query: { session_token: sToken }
                  });
                }
              }
            );
          }
        }
      } catch (err) {
        this.isLoading = false;

        toast.error(
          'We are having problem to send money to this recipient, please contact support or select other recipient!',
          {
            position: toast.POSITION.BOTTOM_CENTER,
            icon: false,
            closeButton: false,
            hideProgressBar: true,
            toastClassName: 'text-danger text-center',
            // bodyClassName: 'w-100',
            autoClose: 5000
          }
        );
      }
    },
    async extractToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const token = queryParams.get('session_token');
        await this.tokenAc('');
        await this.sessionTokenAc(token);
      }
      if (queryParams.has('recipient_key')) {
        this.recipientKey = queryParams.get('recipient_key');
      }
    }
  },

  watch: {
    'formData.mobile_number': function (value) {
      if (!value) {
        this.isValidMobile = false;
        return;
      }
      const ctryCode = this.selectedCountry?.iso2;
      const isValid = isValidNumber(value, ctryCode);

      if (!isValid) {
        this.isValidMobile = false;
      } else {
        this.isValidMobile = true;
      }
    }
  },
  async mounted() {
    this.isLoading = true;

    const url = window.location;
    await this.extractToken(url);

    await this.userDetailsAc();

    this.countryWithCodes = countryCodesList.customList(
      'countryCode',
      ' +{countryCallingCode}'
    );

    this.countryList = currencies.sort((a, b) => (a.name < b.name ? 1 : -1));

    this.currencyList = currencies.sort((a, b) =>
      a.currency > b.currency ? 1 : -1
    );

    const choosedRecipient = this.getRecipient.find((recipient) => {
      return recipient.recipient_key === this.recipientKey;
    });

    this.formData.recipient_email = choosedRecipient.recipient_email || '';
    this.formData.first_name = choosedRecipient.first_name || '';
    this.formData.last_name = choosedRecipient.last_name || '';
    this.formData.dob = choosedRecipient.dob || '';
    this.formData.mobile_number = choosedRecipient.mobile_number || '';

    this.formData.bank_account_number =
      choosedRecipient.bank_account_number || '';

    this.formData.account_routing_number =
      choosedRecipient.account_routing_number || '';

    this.formData.bank_country = choosedRecipient.bank_country;
    this.formData.account_type = choosedRecipient.account_type;
    this.formData.relationship = choosedRecipient.relationship;

    const bank_country = this.countryList.find(
      (ctry) => ctry.iso3 === choosedRecipient.bank_country
    );

    this.selectedCountry = bank_country;
    this.selectedCurrency = bank_country;

    this.formData.account_currency = this.selectedCountry.currency;

    const { iso2 } = bank_country;

    this.selectedCountryMobileCode = (this.countryWithCodes[iso2] || '').trim();

    this.isLoading = false;
  }
};
</script>

<style scoped>
.form-control,
select,
.custom-input-datepicker {
  padding: 0.75rem 0.75rem;
  background-color: #f3f3f3 !important;
  color: #2e693d !important;
  outline: none;
}

input:focus,
select:focus {
  box-shadow: none !important;
  border-color: #359f4e;
}

.custom-input-color {
  color: #2e693d !important;
  font-weight: normal;
}

.custom-input-group {
  border: 1px solid #359f4e !important;
  border-radius: 6px;
  box-shadow: none !important;
}
</style>

<style>
.multiselect__tags,
.multiselect__single,
.multiselect__input {
  border: none;
  line-height: initial;
  color: #2e693d;
  background-color: transparent !important;
}

.toggle-switch-button {
  background-color: #ffd0d0 !important;
  border: 1px solid #e4e9f0;
  padding: 0px 0.2rem;
  height: 1.7rem;
  margin: 0px 5px;
  top: -4px;
}

.toggle-switch-button.Active {
  background-color: #008b24 !important;
  text-align: left;
  color: white;
  top: -4px;
}

.toggle-switch-button.Active .switch-button-label {
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 20px;
}

.toggle-switch-button .switch-button-max-label {
  margin-left: 20px;
  margin-right: 4px;
  opacity: 0;
}

.toggle-switch-button .switch-button-knob {
  height: 25px;
  width: 23px;
  border: 1px solid #e4e9f0;
  top: 0px !important;
}

.toggle-switch-button .switch-button-label {
  margin-top: 4px;
  margin-left: 22px;
  margin-right: 0px;
  display: inline-block;
  position: absolute;
  opacity: 1;
}
</style>
