exports.currencies = [
  {
    name: 'Austria',
    iso3: 'AUT',
    iso2: 'AT',
    capital: 'Vienna',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇦🇹'
  },
  {
    name: 'Belgium',
    iso3: 'BEL',
    iso2: 'BE',
    capital: 'Brussels',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇧🇪'
  },
  {
    name: 'Croatia',
    iso3: 'HRV',
    iso2: 'HR',
    capital: 'Zagreb',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇭🇷'
  },
  {
    name: 'Republic of Cyprus',
    iso3: 'CYP',
    iso2: 'CY',
    capital: 'Nicosia',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇨🇾'
  },
  {
    name: 'Estonia',
    iso3: 'EST',
    iso2: 'EE',
    capital: 'Tallinn',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇪🇪'
  },
  {
    name: 'Finland',
    iso3: 'FIN',
    iso2: 'FI',
    capital: 'Helsinki',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇫🇮'
  },
  {
    name: 'France',
    iso3: 'FRA',
    iso2: 'FR',
    capital: 'Paris',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇫🇷'
  },
  {
    name: 'Germany',
    iso3: 'DEU',
    iso2: 'DE',
    capital: 'Berlin',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇩🇪'
  },
  {
    name: 'Greece',
    iso3: 'GRC',
    iso2: 'GR',
    capital: 'Athens',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇬🇷'
  },
  {
    name: 'Hungary',
    iso3: 'HUN',
    iso2: 'HU',
    capital: 'Budapest',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇭🇺'
  },
  {
    name: 'Ireland',
    iso3: 'IRL',
    iso2: 'IE',
    capital: 'Dublin',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇮🇪'
  },
  {
    name: 'Italy',
    iso3: 'ITA',
    iso2: 'IT',
    capital: 'Rome',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇮🇹'
  },
  {
    name: 'Latvia',
    iso3: 'LVA',
    iso2: 'LV',
    capital: 'Riga',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇱🇻'
  },
  {
    name: 'Lithuania',
    iso3: 'LTU',
    iso2: 'LT',
    capital: 'Vilnius',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇱🇹'
  },
  {
    name: 'Luxembourg',
    iso3: 'LUX',
    iso2: 'LU',
    capital: 'Luxembourg',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇱🇺'
  },
  {
    name: 'Malta',
    iso3: 'MLT',
    iso2: 'MT',
    capital: 'Valletta',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇲🇹'
  },
  {
    name: 'Netherlands',
    iso3: 'NLD',
    iso2: 'NL',
    capital: 'Amsterdam',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇳🇱'
  },
  {
    name: 'Poland',
    iso3: 'POL',
    iso2: 'PL',
    capital: 'Warsaw',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇵🇱'
  },
  {
    name: 'Portugal',
    iso3: 'PRT',
    iso2: 'PT',
    capital: 'Lisbon',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇵🇹'
  },
  {
    name: 'Slovakia',
    iso3: 'SVK',
    iso2: 'SK',
    capital: 'Bratislava',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇸🇰'
  },
  {
    name: 'Slovenia',
    iso3: 'SVN',
    iso2: 'SI',
    capital: 'Ljubljana',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇸🇮'
  },
  {
    name: 'Spain',
    iso3: 'ESP',
    iso2: 'ES',
    capital: 'Madrid',
    currency: 'EUR',
    currency_name: 'Euro',
    currency_symbol: '€',
    region: 'Europe',
    emoji: '🇪🇸'
  }
];
