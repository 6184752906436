exports.requiredFieldSender = {
  USA: [
    {
      field: 'occupation'
    },
    {
      field: 'province_state'
    },
    {
      field: 'zip_code'
    }
  ]
};
