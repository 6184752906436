<template>
  <Loader v-if="isLoading" />
  <div class="mt-3 mb-4" v-else>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-success fw-bold fs-5">Transaction preview</p>
    </div>
    <div class="d-flex justify-content-center mb-0">
      <p class="text-muted">
        Please check all the information below and <br class="d-lg-none" />
        confirm the transaction.
      </p>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card w-50">
        <div class="card-body">
          <div class="">
            <h5 class="text-success mb-3 fw-bold">
              Review your transaction details
            </h5>

            <div class="row row-cols-1 mb-1">
              <div class="col">
                <small class="text-muted"> You withdraw</small>
              </div>
              <div class="col">
                <h6 class="fw-bold">
                  {{ transactionInfo.amount_in }}
                  {{ transactionInfo.amount_in_asset }}
                </h6>
              </div>
            </div>
            <div class="row row-cols-1 mb-1">
              <div class="col">
                <small class="text-muted"> Recipient gets </small>
              </div>
              <div class="col">
                <h6 class="fw-bold">
                  {{ transactionInfo.amount_out }}
                  {{ transactionInfo.amount_out_asset }}
                </h6>
              </div>
            </div>
            <div class="row row-cols-1 mb-1">
              <div class="col">
                <small class="text-muted"> Fee </small>
              </div>
              <div class="col">
                <h6 class="fw-bold">
                  {{ transactionInfo.amount_fee }}
                  {{ transactionInfo.amount_out_asset }}
                </h6>
              </div>
            </div>
            <div class="row row-cols-1 mb-1">
              <div class="col">
                <small class="text-muted">Date & time</small>
              </div>
              <div class="col">
                <h6 class="fw-bold">
                  {{ formatterDateTime(transactionInfo.started_at) }}
                </h6>
              </div>
            </div>
            <div class="row row-cols-1 mb-1">
              <div class="col">
                <small class="text-muted">Transaction type</small>
              </div>
              <div class="col">
                <h6 class="fw-bold">{{ transactionInfo.kind }}</h6>
              </div>
            </div>
            <div class="row row-cols-1 mb-1">
              <div class="col">
                <small class="text-muted">Transaction ID</small>
              </div>
              <div class="col">
                <h6 class="fw-bold">{{ transactionInfo.transaction_id }}</h6>
              </div>
            </div>
          </div>
          <hr class="text-success" />
          <h6 class="mt-3 mb-3 text-success fw-bold">Recipient details</h6>

          <div class="row row-cols-1 mb-1">
            <div class="col">
              <small class="text-muted">Account holder name</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">{{ recipientInfo.recipient_name }}</h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-1">
            <div class="col">
              <small class="text-muted">Bank account/IBAN number</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">{{ recipientInfo.bank_account_number }}</h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-1">
            <div class="col">
              <small class="text-muted">Country</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">
                {{ countryFormatter(recipientInfo.bank_country) }}
              </h6>
            </div>
          </div>
          <div class="row row-cols-1 mb-1">
            <div class="col">
              <small class="text-muted">Currency</small>
            </div>
            <div class="col">
              <h6 class="fw-bold">{{ recipientInfo.account_currency }}</h6>
            </div>
          </div>
          <div class="mb-3 mt-3" v-if="!getKycURL">
            <button
              class="btn btn-success w-100"
              @click.prevent="TxConfirmHandler"
            >
              Confirm
            </button>
          </div>
          <div class="mb-3 mt-3" v-if="getKycURL">
            <button
              class="btn btn-success w-100"
              @click.prevent="TxKYCHandler"
              :class="{ disabled: disabledKYCButton }"
            >
              Confirm and verify identity
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import countryList from 'countries-list';

import Loader from '../components/Loader.vue';

import { formatter } from '../helpers/formatDateTime.js';
import { currencies } from '../data/currencies.js';

export default {
  name: 'ConfirmSection',
  data() {
    return {
      recipientInfo: {},
      transactionInfo: {},
      disabledKYCButton: false,
      isLoading: false
    };
  },

  components: {
    Loader
  },

  methods: {
    ...mapActions([
      'txConfirmAc',
      'tokenAc',
      'sessionTokenAc',
      'errorAc',
      'fetchTxDetails',
      'fetchTxAc'
    ]),

    formatterDateTime(date) {
      return formatter(date);
    },

    countryFormatter(country) {
      const iso = country && country.length === 2 ? 'iso2' : 'iso3';
      const ccy = currencies.find((c) => c[iso] === country);
      return ccy?.name || country;
    },

    async TxConfirmHandler() {
      try {
        this.isLoading = true;

        const response = await this.txConfirmAc(this.getAmountInfo);

        if (response.statusCode === 200) {
          this.isLoading = false;
          const sToken = response.sessionToken;

          this.$router.push({
            path: '/sep24/transaction/more_info',
            query: {
              session_token: sToken
            }
          });
        }
      } catch (err) {
        this.isLoading = false;

        console.error(err);

        const statusCode = (err.response && err.response.status) || 500;
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message;
        await this.errorAc({ statusCode, message });
        this.$router.push({ path: '/error', query: { statusCode } });
      }
    },

    async TxKYCHandler() {
      this.isLoading = true;
      this.disabledKYCButton = true;

      const kycUrl = this.getKycURL;
      window.location.href = kycUrl;
    },

    async extractURLToken(url) {
      const urlObj = new URL(url);
      const queryParams = new URLSearchParams(urlObj.search);

      if (queryParams.has('session_token')) {
        const token = queryParams.get('session_token');
        await this.tokenAc(token);
        await this.sessionTokenAc(token);
      }
    }
  },
  computed: {
    ...mapGetters(['getAmountInfo', 'getTxDetails', 'getKycURL'])
  },
  async mounted() {
    this.isLoading = true;

    const url = window.location;
    await this.extractURLToken(url);

    await this.fetchTxDetails();

    this.transactionInfo = this.getTxDetails.transaction;
    this.recipientInfo = this.getTxDetails.recipient;

    this.isLoading = false;
  }
};
</script>

<style scoped></style>
