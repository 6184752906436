// Validate form data
exports.validate = (obj = {}) => {
  // check the obj itself
  if (Object.keys(obj).length === 0) {
    return false;
  }

  // iterate over the keys of the object
  for (const key of Object.keys(obj)) {
    // check the value of the key
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      console.log(`${key} is empty or undefined`);
      return false;
    }

    // check if the number is positive
    if (typeof obj[key] === 'number' && !obj[key]) {
      console.log(`${key} must be positive`);
      return false;
    }
  }

  // all of the required fields are filled out
  return true;
};
